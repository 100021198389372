import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "app/core/services/admin/user/user.service";
import { ElementsConfigurationService } from "app/core/services/project/project/elements.service";
import { ProjectConfigurationStepperService } from "app/core/services/project/project/stepper.service";
import { DROPOWN_SETTINGS } from "app/shared/components/form/drop-down/settings";
import { IMaestroEntity, MaestroElementTypes, MaestroElements, MaestroProject, MaestroVersions } from "app/shared/models";
import { ElementConfiguration, ElementConfigurations } from "app/shared/models/project/elements-configuration";
import { ConfigurationStepComponent } from "app/shared/models/project/step-component.interface";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { of } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
    selector: "app-configuration-step-products",
    templateUrl: "./elements.component.html",
})
export class ElementsConfigurationComponent extends ConfigurationStepComponent<ElementConfigurations> implements OnInit {

    constructor(
        private _formBuilder: FormBuilder,
        private _service: ElementsConfigurationService,
        private _route: ActivatedRoute,
        private _stepper: ProjectConfigurationStepperService,
        private _userService: UserService
    ) {
        super();
    }
    submitted: boolean;

    form: FormGroup;
    readonly typesFormArray = this._formBuilder.array([]);
    elementTypes: MaestroElementTypes;
    projectId: number;
    elementConfigurations: ElementConfigurations;
    canUpdate: boolean;

    checkCircle = faCheckCircle;
    timesCircle = faTimesCircle;
    init: boolean = true;

    @ViewChild(NgbNav) nav: any;

    @Output() eventSubmitted = new EventEmitter<any>();

    versionsByType: { [typeId: number]: MaestroVersions } = {};
    elementsByType: { [typeId: number]: MaestroElements } = {};
    fieldsByType: { [typeId: number]: IMaestroEntity[] } = {};
    tagsByType: { [typeId: number]: IMaestroEntity[] } = {};
    baseElementsByType: { [typeId: number]: MaestroElements } = {};
    project: MaestroProject;

    dropdownSettings: IDropdownSettings = DROPOWN_SETTINGS.multiple;

    @Input() elements: any;
    noProducts: { [key: number]: { hasProducts: boolean, id: number, name: string } } = {};
    tabNoProducts = false;

    public function
    ngOnInit(): void {

        this.elements = this._route.snapshot.data.elements;
        this.projectId = this.elements.projectId;
        if(!this._stepper.withProduct.value){

            this._stepper.go(0, this.projectId);
        }


        const acl = this._userService.getUserAclFromToken();
        this.canUpdate = /*this.elements.auths.includes("ON_PROJECT_PROJECT_EDIT") ||*/ acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1;

        this.elementTypes = this.elements.types;


        this.elementConfigurations = this.elements.configs;

        this.versionsByType = this.elements.versionsByType;

        this.form = this._initForm(this.elementConfigurations);

        if (this.form.get("elementTypes").value.length) {
            this._refreshTabs(this.form.get("elementTypes").value);
        }

        this._stepper.setCurrentStep(1);
    }

    /**
     * Save configuration
     */
    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this._service.saveConfiguration(Number(this.projectId), this._formToObject(this.form)).subscribe((_) => {
                this.eventSubmitted.next(this._formToObject(this.form));
                this._stepper.validStep(1);
                this._stepper.goToNext(this.projectId);
            });
        }
    }

    isDeactivable(): boolean {
        return true;
    }

    /**
     * Init the form giving configs
     *
     * @param configs
     * @returns
     */
    protected _initForm(configs: ElementConfigurations) {
        const form = this._formBuilder.group({
            elementTypes: [{ value: configs.map((config) => ({ id: config.id, name: config.name })), disabled: !this.canUpdate }, Validators.required],
            tabs: this.typesFormArray,
        });

        configs.forEach((conf) => {
            this.typesFormArray.push(this._initTabForm(conf));

            /*if (conf.version) {
                this._updateFieldAndElementList(
                    form.get("elementTypes").value.map((type) => type.id),
                    conf.version.id
                );
            }*/
        });
        form.get("elementTypes").valueChanges.subscribe((elementType) => this._refreshTabs(elementType));
        return form;
    }

    /**
     * Refresh tab data when selectng data models
     *
     * @param selectedTypes
     */
    private _refreshTabs(selectedTypes: ElementConfigurations) {
        this._updateVersionList(selectedTypes).subscribe((data) => {

            for (const object of selectedTypes) {
                if (!this.noProducts.hasOwnProperty(object.id)) {
                    this.noProducts[object.id] = {
                        hasProducts: Object.keys(data).length > 0 && data[object.id].length > 0,
                        id: object.id,
                        name: object.name
                    };
                    this.tabNoProducts = !(Object.keys(data).length > 0 && data[object.id].length > 0);
                }
            }

            this._refreshFormArray(selectedTypes, this.typesFormArray);
            if (this.nav) {
                this.nav.select(this.typesFormArray.length - 1);
            }
        });
    }

    private _refreshFormArray(selection: ElementConfigurations, formArray: FormArray) {
        for (let i = formArray.controls.length - 1; i >= 0; i--) {
            const control = formArray.controls[i];
            const typeIndex = selection.find((type) => type.id === control.get("typeId").value);

            if (!typeIndex) {
                formArray.removeAt(i);
            }
        }

        selection.forEach((type) => {
            const typeIndex = formArray.controls.find((control) => type.id === control.get("typeId").value);
            if (!typeIndex) {
                formArray.push(this._initTabForm(type)); // <== *there see below
            }
        });
    }

    /**
     * Init tab form
     * @param data
     * @returns
     */
    private _initTabForm(data: ElementConfiguration): FormGroup {
        const form = this._formBuilder.group({
            version: [{ value: data.version ? [data.version] : [], disabled: !this.canUpdate }, Validators.required],
            fields: [{ value: data.fields || [], disabled: !this.canUpdate }, Validators.required],
            elements: [{ value: data.elements || [], disabled: !this.canUpdate }, Validators.required],
            tags: [{ value: data.tags || [], disabled: !this.canUpdate }],

            //data should not be declare here imo
            typeId: data.id,
            title: data.name,
        });
        //this.init = true;
        // bind value change
        form.get("version").valueChanges.subscribe((version) => {
            if (version && version.length) {
                this._updateFieldAndElementList([data.id], version[0].id);
            }
        });

        form.get("tags").valueChanges.subscribe((tags) => {
            const ids = tags.map((tag) => tag.id);
            this.filterElementsByTags(ids, form.value.typeId);
            // @TODO: At init load all elements (alredy done find how to use it or split request element and tag or recall ...) since it's empty then load only selected
            // this._updateFieldAndElementList([data.id], ids);
        });

        return form;
    }

    private _updateVersionList(selectedTypes: MaestroVersions) {
        const types = selectedTypes.filter((type) => !this.versionsByType[type.id]);

        if (types.length) {
            return this._service.getVersionListByTypes(types.map((type) => type.id)).pipe(tap((versionsByType) => Object.assign(this.versionsByType, versionsByType)));
        } else {
            return of(this.versionsByType);
        }
    }

    private _updateFieldAndElementList(typeIds: number[], versionId: number) {
        return this._service.getElementAndFieldList(versionId, typeIds).subscribe((data) => {
            Object.assign(this.fieldsByType, data.fieldsByType);
            Object.assign(this.elementsByType, data.elementByType);
            Object.assign(this.tagsByType, data.tagsByType);
            Object.assign(this.baseElementsByType, data.elementByType);
        });
    }

    protected _formToObject(form: FormGroup): any {
        return {
            lastStep: this._stepper.isLastStep(),
            data: form.get("tabs").value.map(
                (data) =>
                    <ElementConfiguration>{
                        id: data.typeId,
                        name: data.title,
                        version: data.version[0],
                        fields: data.fields,
                        elements: data.elements,
                        tags: data.tags,
                    }
            ),
        };
    }

    onBack() {
        this._stepper.goToPrevious(this.projectId);
    }

    filterElementsByTags(ids: [number], typeId: number) {
        if (ids.length) {
            let data = [];

            this.baseElementsByType[typeId].map((element: any) => {
                const found = element.tagsId.some((id) => ids.includes(id));

                if (found) {
                    data.push(element);
                }
            }, ids);

            this.elementsByType[typeId] = data;
        } else {
            this.elementsByType[typeId] = this.baseElementsByType[typeId];
        }
    }

    searchTab(valueToFind)
    {
        this.tabNoProducts = !this.noProducts[valueToFind].hasProducts;
    }
}
