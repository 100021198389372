import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-view-tab-value",
    templateUrl: "./view-tab-value.component.html",
})
export class ViewTabValueComponent implements OnInit {
    @Input() data: any;
    constructor() { }

    ngOnInit() { }
}
