import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { faImages, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { HttpClientUploadService } from "@wkoza/ngx-upload";
import { UserService } from "app/core/services/admin/user/user.service";
import { BreadcrumbFlatplanToolbarService } from "app/core/services/global/layout/bread-crumb-flatplan-toolbar.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { FlatplanViewService } from "app/core/services/project/export/flatplan-view.service";
import { PageOptionTabService } from "app/core/services/project/export/page-option-tab.service";
import { PageService } from "app/core/services/project/export/page.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { ViewButtonsDef, ViewType } from "app/shared/components/view-buttons";
import { MaestroElements, MaestroExport, MaestroPage, MaestroPages } from "app/shared/models";
import { FlatplanResources } from "app/shared/models/project/flatplan-resources";
import { ThumbnailItemEvent, ThumbnailMenuAction } from "app/shared/models/project/thumbnail";
import { UnsubscribePipe } from "app/shared/pipes";
import { MAESTRO_ROUTES } from "app/shared/routes";
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";


/**********
 * Modals *
 **********/
import swal from "sweetalert2";

@Component({
    selector: "app-flatplan-view",
    templateUrl: "./flatplan-view.component.html",
    styleUrls: ["./flatplan-view.component.scss"],
})
export class FlatplanViewComponent implements OnInit, OnDestroy {
    globalMode: "image" | "info" = "image";

    flatplan: MaestroPages = [];
    covers: MaestroPages = [];
    baseFlatplan: MaestroPages = [];
    isOpen = false;
    isOpenFlatplan = false;
    isOpenDuplicate = false;
    isOpenImportPdf = false;
    elements: MaestroElements;
    export: MaestroExport;
    readonly faInfoCircle = faInfoCircle;
    readonly faImages = faImages;
    templateList: any[];
    pagesMaster: MaestroPages = [];

    previewUrl: any;

    isExportHtml: boolean;
    isExportMarketing: boolean;
    isExportBooklet: boolean;
    isExportFlatplan: boolean;
    selectPDF: SafeUrl = "";

    public currentPage: MaestroPage;

    @ViewChild("newPage") private newPage: SwalComponent;
    @ViewChild("newPageDuplicate") private newPageDuplicate: SwalComponent;
    @ViewChild("updatePage") private updatePage: SwalComponent;
    @ViewChild("updatePageDuplicate") private updatePageDuplicate: SwalComponent;
    @ViewChild("newFlatplan") private newFlatplan: SwalComponent;
    @ViewChild("changeElements") private changeElements: SwalComponent;
    @ViewChild("changeTemplate") private changeTemplate: SwalComponent;
    @ViewChild("preview") private preview: SwalComponent;
    @ViewChild("importPDF") private importPDF: SwalComponent;

    views: ViewButtonsDef = [];

    private _subscriptions = [];
    private maxPages: number;
    private idCdf: number;
    private viewButtonsSubscription: Subscription;
    viewType: ViewType;

    form: FormGroup;
    formDuplicate: FormGroup;
    formUpdateDuplicate: FormGroup;
    formFlatplan: FormGroup;
    changeElementsForm: FormGroup;
    changeTemplateForm: FormGroup;
    version: number;
    pdf: any;
    savePdf: boolean = false;

    submitted = false;
    submittedFlatplan = false;
    private _subscription: Subscription;

    selectElementLimit: any = null; // Need to be null to display select all checkbox

    products: any[] = [];
    baseProducts: any[];

    exportId: number;
    projectId: number;

    versionName: string;

    private viewFormat = {
        thumbnail: "thumbnail",
        list: "list",
    };

    private blobUrl: string;
    private pageToAdd: any;

    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _service: FlatplanViewService,
        private _exportService: ProjectExportService,
        private _unsusbscribe: UnsubscribePipe,
        private _page: PageService,
        private _toolbar: BreadcrumbFlatplanToolbarService,
        private _translate: TranslateService,
        public readonly swalTargets: SwalPortalTargets,
        private fb: FormBuilder,
        private swalModalService: SwalModalService,
        private _sanitizer: DomSanitizer,
        private _projectService: ProjectService,
        private _userService: UserService,
        public uploader: HttpClientUploadService,
        private pageOptionService: PageOptionTabService
    ) {}

    ngOnInit(): void {
        this.exportId = this._route.parent.snapshot.params.id;
        this.projectId = this._route.parent.parent.parent.parent.snapshot.params.id;
        this.isExportHtml = window.location.href.includes("html");
        this.isExportMarketing = window.location.href.includes("marketing");
        this.isExportBooklet = window.location.href.includes("booklet");
        this.isExportFlatplan = window.location.href.includes("flatplan");
        this.version = this._route.parent.queryParams["_value"]["version"];

        if (this.isExportFlatplan) {
            this.views.push(
                { type: ViewType.Thumbnails, text: this._translate.instant("projects.export.flatplan"), options: this.viewFormat.thumbnail },
                { type: ViewType.List, text: this._translate.instant("projects.export.list"), options: this.viewFormat.list }
            );
        } else {
            let text;
            const type = ViewType.List;
            const options = this.viewFormat.list;

            if (this.isExportHtml) {
                text = this._translate.instant("projects.export.html");
            } else {
                text = this._translate.instant("projects.export.marketing");
            }
        }

        this.form = this.fb.group({
            name: [""],
            pageLength: [0, [Validators.required, Validators.min(0)]],
            elementsLength: [0, [Validators.required, Validators.min(0)]],
            elementList: [{ value: [], disabled: true }],
            template: [{ value: [], disabled: true }],
        });

        this.formDuplicate = this.fb.group({
            name: [""],
            pages: [[], Validators.required],
            pageLength: [{ value: 0, disabled: true }, [Validators.required, Validators.min(0)]],
            elementsLength: [{ value: 0, disabled: true }, [Validators.required, Validators.min(0)]],
            elementList: [{ value: [], disabled: true }],
        });

        this.formUpdateDuplicate = this.fb.group({
            id: [""],
            name: [""],
            elementList: [{ value: [], disabled: true }],
            pageLength: [{ value: this.isExportMarketing ? 1 : 0, disabled: true }, [Validators.required, Validators.min(0)]],
            elementsLength: [{ value: 0, disabled: true }, [Validators.required, Validators.min(0)]],
        });

        this.formFlatplan = this.fb.group({
            pageLength: [0, [Validators.required, Validators.min(0)]],
        });

        this.changeElementsForm = this.fb.group({
            elements: [[]],
        });

        this.changeTemplateForm = this.fb.group({
            template: [[]],
        });
        const resources: FlatplanResources = this._route.snapshot.data.resources;

        this.export = resources.export;
        this.elements = resources.elements;

        // If there is no flatplan associate, then create one
        if (!resources.idCdf) {
            if (this.isExportHtml || this.isExportMarketing || this.isExportBooklet) {
                this._createCdf({ pageLength: 0 });
            } else {
                this._openCdfCreationModal();
            }
        } else {
            this.idCdf = resources.idCdf;
            this._service.getInfoCdf(resources.export.id).subscribe((data) => {
                this.maxPages = data.data.max;
                this._toolbar.nbpages = data.data.nb_page;
                this._toolbar.maxpages = this.maxPages;
            });
        }

        this._initFlatplan();

        if (this.version) {
            this._projectService.getVersion(this.version).subscribe((data) => {
                this.versionName = data.data.name;
                this._layout.breadcrumb.setPath({ name: this.versionName, routerLink: null }, 2);
            });
        } else {
            let path = `/${MAESTRO_ROUTES.projects.base}/${this.projectId}/${MAESTRO_ROUTES.projects.export}/${this.exportId}/`;
            
            if(this.isExportFlatplan){
                path = path + "flatplan";
            } else if (this.isExportHtml) {
                path = path + "html";
            } else if(this.isExportBooklet){
                path = path + "booklet";
            } else {
                path = path + "marketing";
            }

            if(this.version){
                path += "?version="+this.version;
            }

            this._layout.breadcrumb.setPath({ name: this.export.name, routerLink: path }, 2);
        }
    }

    ngOnDestroy(): void {
        URL.revokeObjectURL(this.blobUrl); // For memory management;
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        this._unsusbscribe.transform(this._subscriptions);
        this._layout.actionButton.title = null;
        this._layout.actionButton.enable = false;
        this._layout.viewButtons.enable = false;
        this._toolbar.enabled = false;
        this._layout.searchInput.enable = false;
        this._exportService.clearPreviews();
    }

    onAddPageClick() {
        this._openPageCreationModal();
    }

    /**
     * Init flatplan
     * @param maxpages
     */
    _initFlatplan(maxpages?: number) {
        if (this.idCdf) {
            if (this.version) {
                this._service.getById(this.idCdf).subscribe((data) => {
                    data.pages.forEach((page) => {
                        if (this.pagesMaster.filter((pageMaster) => pageMaster.id == page.id).length <= 0) {
                            if ("" === page.name) {
                                page.name = `Unamed page N°${page.id} folio N°${page.folio}`; // Temporary fix to avoid blanck checkbox (cannot be done in back since it will display this concatened name in master page list)
                            }
                            this.pagesMaster.push(page);
                        }
                    });
                });
            }
            this._service.getById(this.idCdf, this.version).subscribe((pages) => {
                this.flatplan = pages.pages ? pages.pages : [];
                this.covers = pages.covers ? pages.covers : [];
                
                for (let i = 0; i < this.flatplan.length; i++) {
                    for (let j = 0; j < this.flatplan[i].elements.length; j++) {
                        let elem = this.elements.find(element => {
                            return element.id == this.flatplan[i].elements[j].id
                        });
                        if(elem){
                            this.flatplan[i].elements[j].name = elem.name;
                        }
                    }
                }

                this.baseFlatplan = pages.pages ? pages.pages : [];
                this.products = pages.products;
                this.baseProducts = pages.products;

                this._toolbar.nbpages = pages.pages ? pages.pages.length : 0;
                this._toolbar.idFlatplan = this.idCdf;
                if (this.isExportHtml) {
                    this._toolbar.exportType = "html";
                } else if (this.isExportMarketing || this.isExportBooklet) {
                    this._toolbar.exportType = "marketing";
                } else {
                    this._toolbar.exportType = "cdf";
                }
                if (maxpages) {
                    this._toolbar.maxpages = maxpages;
                }

                this._exportService.preparePreview(this.flatplan, this.version);
                this._exportService.preparePreview(this.covers, this.version);

                this._initLayout();
            });
        }
    }

    /**
     * Handle action on a page
     * @param event
     */
    onPageAction(event: ThumbnailItemEvent) {
        switch (event.action) {
            case ThumbnailMenuAction.UpdatePage:
                this.setMaxElements(Number(event.data.elementLength));

                this.form.patchValue({
                    name: event.data.name,
                    pageLength: Number(event.data.pageLength),
                    elementsLength: Number(event.data.elementLength),
                    elementList: event.data.elements,
                });

                this.setTemplateList(event.data.template);
                this.currentPage = event.data;

                if (this.version) {
                    this.formUpdateDuplicate.patchValue({
                        id: event.data.pageDuplicateId,
                        name: event.data.name,
                        elementList: event.data.elements,
                        pageLength: event.data.pageLength,
                        elementsLength: event.data.elementLength,
                    });
                    


                    if(event.data.isImport){
                        this.form.get('pageLength').disable();
                        this.form.get('elementsLength').disable();
                        this.form.get('elementList').disable();
                    }

                    if (this.isExportBooklet) {
                        this.setMaxElements(0);
                    }

                    +event.data.elementLength || this.isExportBooklet ? this.formUpdateDuplicate.controls.elementList.enable() : this.formUpdateDuplicate.controls.elementList.disable();

                    this.updatePageDuplicate.fire();
                } else {
                    this.form.patchValue({
                        name: event.data.name,
                        pageLength: Number(event.data.pageLength),
                        elementsLength: Number(event.data.elementLength),
                        elementList: event.data.elements
                    });
                    
                    if(event.data.isImport == true){
                        this.form.get('pageLength').disable();
                        this.form.get('elementsLength').disable();
                        this.form.get('elementList').disable();
                    } else {
                        this.form.get('pageLength').enable();
                        this.form.get('elementsLength').enable();
                        this.form.get('elementList').enable();
                    }
                    
                    if (this.isExportBooklet) {
                        this.setTemplateList();
                        this.setMaxElements(0);
                    }
                    this.updatePage.fire();
                }
                break;
            case ThumbnailMenuAction.PageDelete:
                this.swalModalService.deleteGroupPage(event.data.name, (event.data as any).nbpage).then((result) => {
                    if (result.value) {
                        this._deletePage(event.data);
                    }
                });
                break;
            case ThumbnailMenuAction.Preview:
                this._openPagePreviewModal(event.data);
                break;

            case ThumbnailMenuAction.Edit:
                this._goToPageEdition(event.data);
                break;
            case ThumbnailMenuAction.ImportPDF:
                this._openImportPDFModal(event.data)
                break
        }
    }

    /**
     * Reorder pages
     * @param flatPlanIds
     */
    onPageMove(flatPlanIds: number[]) {        
        if(this.version){
            this._saveVersionFlatplan(flatPlanIds);
        } else {
            this._saveFlatplan(flatPlanIds);
        }

    }

    /**
     * Toggle info view
     */
    toggleInfo() {
        this.globalMode = this.globalMode === "image" ? "info" : "image";
    }

    /**
     * Init the layout
     */
    private _initLayout() {
        const acl = this._userService.getUserAclFromToken();
        if (acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1 || this.version) {
            const title = this.isExportFlatplan ? "project.flatplan.add" : "project.flatplan.addExp";
            this._layout.actionButton.title = title;
            this._layout.actionButton.enable = true;
            // Define action button
            this._subscriptions.push(this._layout.actionButton.click$.subscribe((_) => this.onAddPageClick()));
        }

        this._layout.viewButtons.enable = true;
        this._layout.viewButtons.actions = this.views;

        this._changeView();

        this._layout.breadcrumb.setPath(
            {
                name: this.isExportHtml
                    ? this._translate.instant("projects.export.html")
                    : this.isExportMarketing || this.isExportBooklet
                    ? this._translate.instant("projects.export.marketing")
                    : this._translate.instant("projects.export.flatplan"),
                routerLink: null,
            },
            3
        );
        this._layout.searchInput.enable = true;
        this._subscription = this._layout.searchInput.filter$.subscribe((value) => this.onFilterList(value));
        this._subscriptions.push(
            this._layout.viewButtons.click$.subscribe((view) => {
                this.viewType = view.type;
                this._subscription.unsubscribe();

                switch (this.viewType) {
                    case ViewType.List:
                        this._layout.searchInput.enable = true;
                        this._subscription = this._layout.searchInput.filter$.subscribe((value) => this.onFilterList(value));

                        let name;
                        if (this.isExportHtml) {
                            name = this._translate.instant("projects.export.html");
                        } else if (this.isExportBooklet || this.isExportMarketing) {
                            name = this._translate.instant("projects.export.marketing");
                        } else {
                            name = this._translate.instant("projects.flatplan.list");
                        }
                        this._layout.breadcrumb.setPath({ name: name, routerLink: null }, 3);
                        break;
                    case ViewType.Thumbnails:
                        this._layout.searchInput.enable = true;
                        this._subscription = this._layout.searchInput.filter$.subscribe((value) => this.onFilterList(value));
                        this._layout.breadcrumb.setPath({ name: this._translate.instant("projects.export.flatplan"), routerLink: null }, 3);
                }
            })
        );

        this._toolbar.enabled = true;
    }

    /**
     * Create flatplan
     *
     * @param data
     */
    private _createCdf(data) {
        this._service.createCdf(this.export.id, data.pageLength).subscribe((response: any) => {
            this.idCdf = response.data;
            this._initFlatplan(data.pageLength);
        });
    }

    /**
     * Create a new page
     */
    private _createPage(page) {
        if (this.idCdf) {
            this.pageToAdd = page;
            this.templateList = [];
            this._page
                .createPage(this.idCdf, {
                    name: page.name,
                    nbPages: page.pageLength,
                    nbProducts: page.elementsLength,
                    elements: page.elementList,
                    templateId: page.template ? (page.template[0] ? page.template[0].id : null) : null,
                    isExportBooklet: this.isExportBooklet,
                })
                .subscribe((res) => {
                    this._service.getById(this.idCdf).subscribe((pages) => {
                        const control = this.pageToAdd && this.pageToAdd.template && this.pageToAdd.template.length > 0 ? true : false;

                        if (control) {
                            this._exportService.generate(res.data).subscribe((observable) => {
                                if (observable instanceof Observable) {
                                    observable.subscribe((response) => {});
                                }
                            });
                        }

                        this._resetForm();
                        this.flatplan = pages.pages;
                        this.products = pages.products;
                        this._toolbar.nbpages = this.flatplan.length;
                        

                        let version = this._route.queryParams["_value"]["version"];
                        undefined !== version ? null : (version = null);

                        this._exportService.preparePreview(this.flatplan, version);
                        this._exportService.preparePreview(this.covers, version);
                    });
                });
        }
    }

    /**
     * Create a new page
     */
    private _createDuplicate(page) {
        if (this.idCdf) {
            this.pageToAdd = page;
            this._page
                .createPageDuplicate(page.pages[0].id, {
                    name: page.name,
                    version: this.version,
                    elements: page.elementList,
                })
                .subscribe((res) => {
                    this._service.getById(this.idCdf, this.version).subscribe((pages) => {
                        const control = this.pageToAdd && page.pages[0].template && page.pages[0].template.name !== "" ? true : false;

                        if (control) {
                            this._exportService.generate(page.pages[0].id, 0, this.version, res.data).subscribe((observable) => {
                                if (observable instanceof Observable) {
                                    observable.subscribe((response) => {});
                                }
                            });
                        }

                        this._resetForm();
                        this.flatplan = pages.pages;
                        this.covers = pages.covers;
                        this.products = pages.products;
                        this._toolbar.nbpages = this.flatplan.length;
                    });
                });
        }
    }

    /**
     * Delete a page
     *
     * @param page
     */
    private _deletePage(page) {
        if (page.pageDuplicateId) {
            this._service.deleteDuplicatePage(page.pageDuplicateId).subscribe(() => {
                this._service.getById(this.idCdf, this.version).subscribe((pages) => {
                    this.flatplan = pages.pages;
                    this.covers = pages.covers;
                    this.products = pages.products;
                    this._toolbar.nbpages = this.flatplan.length;
                });
            });
        } else {
            this._service.deleteAllPage(page.group).subscribe(() => {
                this._service.getById(this.idCdf).subscribe((pages) => {
                    this.flatplan = pages.pages;
                    this.covers = pages.covers;
                    this.products = pages.products;
                    this._toolbar.nbpages = this.flatplan.length;

                    let version = this._route.queryParams["_value"]["version"];
                    undefined !== version ? null : (version = null);

                    this._exportService.preparePreview(this.flatplan, version);
                    this._exportService.preparePreview(this.covers, version);

                    
                });
            });
        }
    }

    /**
     * Redirect to edition page
     *
     * @param page
     */
    private _goToPageEdition(page) {
        const reltiveUrl = [MAESTRO_ROUTES.projects.page, page.id];
        const options: NavigationExtras = {
            relativeTo: this._route.parent,
            queryParams: {
                version: this._route.parent.queryParams["_value"]["version"],
                duplicate: page.pageDuplicateId,
            },
        };
        
        this._exportService.checkAutoGenerate(page.id, page.pageDuplicateId).subscribe((check) => {
            
            if(check){
                this._exportService.generate(page.id, 0, this._route.parent.queryParams["_value"]["version"], page.pageDuplicateId).subscribe((observable) => {
                    if (observable instanceof Observable) {
                        observable.subscribe((response) => {});
                    }
                });
            }
        })

        this._router.navigate(reltiveUrl, options);
    }

    /**
     * Update flatplan
     * @param ids
     */
    private _saveFlatplan(ids: number[]) {
        this._service.saveFlatplan(this.idCdf, ids).subscribe(() => {
            this._initFlatplan();
        });
    }

    /**
     * Update flatplan
     * @param ids
     */
    private _saveVersionFlatplan(ids: number[]) {
        this._service.updateFolioVersion(this.idCdf, ids).subscribe(() => {
            this._initFlatplan();
        });
    }

    /**
     * Set limit to max for the select elements
     * @param max
     */
    setMaxElements(max: number) {
        max = Number(max);

        if (this.isExportBooklet) {
            this.selectElementLimit = null;
        } else {
            if (max < this.selectElementLimit) {
                this.form.controls.elementList.setValue([]);
                this.formDuplicate.controls.elementList.setValue([]);
            }

            this.selectElementLimit = max;
        }

        if (max === 0 && !this.isExportBooklet) {
            this.form.controls.elementList.disable();
            this.formDuplicate.controls.elementList.disable();
        } else {
            this.form.controls.elementList.enable();
            this.formDuplicate.controls.elementList.enable();
        }
    }

    preConfirm(): void {
        if (this.isOpen) {
            if (!this.form.valid) {
                this.newPage.showConfirmButton = false;
            } else {
                this.newPage.showConfirmButton = true;
            }
        }
    }

    preConfirmFlatplan(): void {
        if (this.isOpenFlatplan) {
            if (!this.formFlatplan.valid) {
                swal.disableButtons();
            } else {
                swal.enableButtons();
            }
        }
    }

    preConfirmImportPDF(): void {
        if (this.isOpenImportPdf) {
            if (!this.savePdf) {
                swal.disableButtons();
            } else {
                swal.enableButtons();
            }
        }
    }

    preConfirmDuplicate(): void {
        if (this.isOpenDuplicate) {
            if (!this.formDuplicate.valid) {
                this.newPageDuplicate.showConfirmButton = false;
            } else {
                this.newPageDuplicate.showConfirmButton = true;
            }
        }
    }

    sendForm(value) {
        if (this.version) {
            this._createDuplicate(value);
        } else {
            this._createPage(value);
        }
    }

    sendFormFlatplan(value) {
        this._createCdf(value);
    }

    private _openPageCreationModal() {
        this.selectElementLimit = null;

        this._resetForm();

        if (this.version) {
            this.isOpenDuplicate = true;
            this.newPageDuplicate.fire();
        } else {
            this.isOpen = true;
            if (this.isExportBooklet) {
                this.setTemplateList();
                this.setMaxElements(0);
            }
            this.newPage.fire();
        }
        setTimeout(() => {
            (document.getElementsByClassName("swal2-actions")[0] as HTMLElement).style.zIndex = "unset";
        }, 100);
    }

    private _openPagePreviewModal(page: MaestroPage) {
        const fileType = this.isExportHtml ? "html" : "pdf";
        const pageDuplicateId = page.pageDuplicateId ? page.pageDuplicateId : null;
        
        this._exportService.getPreview(page.group, fileType, 0, pageDuplicateId).subscribe((response: any) => {
            const header = this.isExportHtml ? "text/html" : "application/pdf";
            this.blobUrl = this._exportService.createBlobUrl(response.data, header);
            if (Array.isArray(response.data)) {
                this.previewUrl = this._exportService.sanitize(this.blobUrl);
                this.preview.fire();
            }
        });
    }

    private _openImportPDFModal(page: MaestroPage){
        this.isOpenImportPdf = true;
        this.selectPDF = "";
        this.importPDF.fire().then((result) => {
            if(result.isConfirmed){
                
                let reader = new FileReader();
                reader.readAsDataURL(this.pdf);
                reader.onload = (event) => {           
                    this.selectPDF = this._sanitizer.bypassSecurityTrustResourceUrl(event.target.result.toString());
                    this.pageOptionService.importPDF(page.heading, this.pdf, this.selectPDF, page.pageDuplicateId).subscribe((data) => {
                        page.isImport = true;
                        let pages = [];
                        pages.push(page);


                        let version = this._route.queryParams["_value"]["version"];
                        undefined !== version ? null : (version = null);
                    
                        this._exportService.preparePreview(this.flatplan, version);
                        this._exportService.preparePreview(this.covers, version);
                    });
                }
            }
            
        });
    }

    private _openCdfCreationModal() {
        setTimeout(() => {
            this.isOpenFlatplan = true;
            this.newFlatplan.fire();
        }, 100);
    }

    redirectToHome(): void {
        this._router.navigate(["/projects", this._route.parent.parent.parent.parent.snapshot.params.id, "dashboard"]);
    }

    /**
     * Filter on products view
     * @param value
     * @returns
     */
    onFilterProducts(value: string): void {
        if (value.length < 3) {
            this.products = this.baseProducts;
            return;
        }

        this.products = this.baseProducts.filter((p) => {
            return p.name.toLowerCase().includes(value.toLowerCase()) || p.page.name.toLowerCase().includes(value.toLowerCase()) || p.page.workflow.toLowerCase().includes(value.toLowerCase());
        });
    }

    /**
     * Filter on list view
     * @param value
     * @returns
     */
    onFilterList(value: string): void {
        if (value.length < 3) {
            this.flatplan = this.baseFlatplan;
            return;
        }

        this.flatplan = this.baseFlatplan.filter((p) => {
            return (
                p.name.toLowerCase().includes(value.toLowerCase()) ||
                p.elements.filter((e) => e.name.toLowerCase().includes(value.toLowerCase())).length > 0 ||
                p.template.name.toLowerCase().includes(value.toLowerCase())
            );
        });
    }

    updatePageProductLength(): void {
        if (this.formDuplicate.controls.pages.value.length >= 1) {
            let page = this.pagesMaster.find((page) => page.id == this.formDuplicate.controls.pages.value[0].id);
            const pageElementLength = +page.elementLength;
            this.selectElementLimit = pageElementLength;

            this.formDuplicate.controls.elementsLength.setValue(pageElementLength);
            this.formDuplicate.controls.pageLength.setValue(page.pageLength);
            this.formDuplicate.controls.elementList.setValue(page.elements);
            pageElementLength > 0 || this.isExportBooklet ? this.formDuplicate.controls.elementList.enable() : this.formDuplicate.controls.elementList.disable();
        } else {
            this.selectElementLimit = 0;
            this.formDuplicate.controls.elementsLength.setValue(0);
            const pageLength =  0;
            this.formDuplicate.controls.pageLength.setValue(pageLength);
            this.formDuplicate.controls.elementList.setValue([]);
            this.formDuplicate.controls.elementList.disable();
        }
    }

    /**
     * Prepare template list
     * @param value
     */
    setTemplateList(value: any = null): void {
        let pageNumber = this.form.controls.pageLength.value;
        if (this.isExportBooklet) {
            pageNumber = 0;
        }
        const elementNumber = this.form.controls.elementsLength.value;
        this.form.controls.template.setValue([]);

        if (!this.templateList || this.templateList.length === 0) {
            this.form.controls.template.disable();
        } else {
            this.form.controls.template.enable();
        }

        if (Number.isInteger(pageNumber) && Number.isInteger(elementNumber) && pageNumber >= 0 && elementNumber >= 0) {
            this._service
                .getTemplatesForPage(this.exportId, pageNumber, elementNumber)
                .pipe(map((r) => r.data))
                .subscribe((data) => {
                    this.templateList = data;
                    if (!this.templateList || this.templateList.length === 0) {
                        this.form.controls.template.disable();
                    } else {
                        this.form.controls.template.enable();
                    }
                    if (value && value.name) {
                        const t = this.templateList.find((te) => te.name === value.name);
                        setTimeout(() => {
                            this.form.controls.template.setValue([t]);
                        }, 50);
                    } else {
                        this.form.controls.template.setValue(null);
                    }
                });
        }
    }

    warningUpdate(duplicate: boolean) {
        const page = this.form.value;

        if ((this.currentPage.template && !page.template) ||(!page.template[0] && this.currentPage.template.name) || (page.template[0] && this.currentPage.template.name != page.template[0].name)) {
            this.swalModalService.updatePage().then((result) => {
                if (result.value) {
                    if (duplicate) {
                        this.updatePageDuplicateAction();
                    } else {
                        this.updatePageAction();
                    }

                    this._exportService.preparePreview(this.flatplan, this.version);
                    this._exportService.preparePreview(this.covers, this.version);
                }
            });
        } else {
            if (duplicate) {
                this.updatePageDuplicateAction();
            } else {
                this.updatePageAction();
            }

            this._exportService.preparePreview(this.flatplan, this.version);
            this._exportService.preparePreview(this.covers, this.version);
        }
    }

    /**
     * Update page
     *
     * @returns
     */
    updatePageAction(): void {
        if (this.form.invalid) {
            return;
        }

        const page = this.form.value;

        this._service
            .updatePage(this.idCdf, this.currentPage.group, {
                name: page.name,
                nbPages: page.pageLength,
                nbProducts: page.elementsLength,
                elements: page.elementList,
                templateId: page.template && page.template[0] ? page.template[0].id : null,
                isExportBooklet: this.isExportBooklet,
            })
            .subscribe(() => {
                const control = page.template && page.template.length > 0 ? true : false;

                if (control) {
                    this._exportService.generate(this.currentPage.id).subscribe((observable) => {
                        if (observable instanceof Observable) {
                            observable.subscribe((response) => {});
                        }
                    });
                }

                this._service.getById(this.idCdf).subscribe((pages) => {
                    this.flatplan = pages.pages;
                    this.covers = pages.covers;
                    this.products = pages.products;
                    this._toolbar.nbpages = this.flatplan.length;
                    this._resetForm();
                });
            });
    }

    /**
     * Update page
     *
     * @returns
     */
    updatePageDuplicateAction(): void {
        if (this.formUpdateDuplicate.invalid) {
            return;
        }

        const page = this.formUpdateDuplicate.value;

        if (this.idCdf) {
            this._page
                .updatePageDuplicate(page.id, {
                    name: page.name,
                    elements: page.elementList,
                })
                .subscribe(() => {
                    this._service.getById(this.idCdf, this.version).subscribe((pages) => {
                        const masterPage = pages.pages.find((p) => p.id == this.currentPage.id && p.pageDuplicateId == page.id);
                        const control = masterPage.template && masterPage.template.name !== "" ? true : false;

                        if (control) {
                            this._exportService.generate(masterPage.id, 0, this.version, page.id).subscribe((observable) => {
                                if (observable instanceof Observable) {
                                    observable.subscribe((response) => {});
                                }
                            });
                        }

                        this._resetForm();
                        this.flatplan = pages.pages;
                        this.covers = page.covers;
                        this.products = pages.products;
                        this._toolbar.nbpages = this.flatplan.length;
                    });
                });
        }
    }

    private _changeView(): void {
        const offsetParent = document.getElementById("flatplan-view").offsetParent;

        for (let view of this.views) {
            if (null !== offsetParent && null !== view.type) {
                if (undefined === this.viewType) {
                    this.viewType = view.type;
                }
                this._layout.viewButtons.default = view;
                break;
            }
        }
    }

    private _resetForm() {
        if (!this.version) {
            this.form.reset({
                elementsLength: 0,
                pageLength: 0,
                elementList: { value: [], disabled: true },
                template: { value: [], disabled: true },
            });
        } else {
            this.formDuplicate.reset({
                elementsLength: 0,
                pageLength: 0,
                elementList: { value: [], disabled: true },
                pages: [],
            });
        }
    }

    showPDF(event){
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        if(event.target.files.length > 0){
            this.pdf = event.target.files[0];
        } else {
            this.pdf = null;
        }
         
        

        reader.onload = (event) => {   
            let self = this;    
            this.selectPDF = this._sanitizer.bypassSecurityTrustResourceUrl(event.target.result.toString());

            // pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js";
            pdfjsLib.GlobalWorkerOptions.workerSrc = "assets/js/pdf.worker.min.js";
            const loadingTask = pdfjsLib.getDocument(this.convertDataURIToBinary(event.target.result.toString()));
            loadingTask.promise.then(function(pdf) {
                self.savePdf = pdf.numPages === 1;
            });
        }
    }

    convertDataURIToBinary(dataURI) {
        let BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
      
        for(var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i);
        }
        return array;
      }



    OnCloseImportPdfModal()
    {
        this.isOpenImportPdf = false;
        this.savePdf = false;
        this.selectPDF = "";
    }

    createCovers() {
        this._exportService.reInitCover(this.exportId).subscribe(data =>{
            this._service.getById(this.idCdf).subscribe((pages) => {
                this._resetForm();
                this.flatplan = pages.pages;
                this.covers = pages.covers;
                this.products = pages.products;
                this._toolbar.nbpages = this.flatplan.length;
                

                let version = this._route.queryParams["_value"]["version"];
                undefined !== version ? null : (version = null);

                this._exportService.preparePreview(this.flatplan, version);
                this._exportService.preparePreview(this.covers, version);
            });      
        });
    }
}
