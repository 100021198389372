import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faEnvelope, faImage } from "@fortawesome/free-regular-svg-icons";
import { faCartArrowDown, faCropAlt, faEdit, faEye, faHandPointer, faPlus, faTrashAlt, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { MediaService } from "app/core/services/dam/media.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { TagService } from "app/core/services/tag/tag.service";
import { UploadService } from "app/core/services/upload/upload.service";
import { CropperComponent } from "app/shared/components/cropper/cropper.component";
import { ThumbButtons } from "app/shared/helpers/thumb-buttons";
import { MaestroTags } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { Thumb } from "app/shared/models/thumb";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { ThumbnailsService } from "../../../../core/services/thumbnails/thumbnails.service";

@Component({
    selector: "[app-thumb]",
    templateUrl: "./thumb.component.html",
    styleUrls: ["./thumb.component.scss"],
})
export class ThumbComponent implements OnInit {
    @Input() media: Thumb;
    @Input() buttons: ThumbButtons;
    @Input() selectable: boolean = false;
    @Input() selectedMedias: number[] = [];
    @Input() linkedMedias: any[] = [];
    @Input() minified: boolean = false; // Simplify interface
    @Input() module: string = "dam";
    @Input() singleSelection: boolean = false;
    @Input() linkAdded: boolean = false;

    damUrl: string;

    readonly faTrashAlt = faTrashAlt;
    readonly faEdit = faEdit;
    readonly faEye = faEye;
    readonly faPlus = faPlus;
    readonly faUnlink = faUnlink;
    readonly faImage = faImage;
    readonly faBucket = faCartArrowDown;
    readonly faEmail = faEnvelope;
    readonly faCrop = faCropAlt;
    readonly faHandPointer = faHandPointer;

    formAddTag: FormGroup;
    dataModelForm: FormGroup;
    isValid: boolean;

    @Output() public unlinked = new EventEmitter<number>();
    @Output() public openModal = new EventEmitter<Thumb>();
    @Output() public deleted = new EventEmitter<number>();
    @Output() public addCart = new EventEmitter<Thumb>();
    @Output() public tagFiltered = new EventEmitter<any>();
    @Output() public tagRemoved = new EventEmitter<void>();
    @Output() public thumbUpdateEvent = new EventEmitter<number>();
    @Output() public selectMedia: EventEmitter<any> = new EventEmitter();
    @Output() public selectExtension: EventEmitter<any> = new EventEmitter();
    @Output() public openCrop: EventEmitter<any> = new EventEmitter();

    @ViewChild("changeMedia") public changeMediaSwal: SwalComponent;
    @ViewChild("addTagModal") public addTagModal: SwalComponent;
    @ViewChild("cropper") public cropper: CropperComponent;
    @ViewChild("datamodel") public dataModelModal: SwalComponent;

    acceptList: string;
    acl: ACL;
    @Input() dataModels = [];

    @Input() tags: MaestroTags = [];
    filteredTags = [];

    get canAddTag(): boolean {
        return this.acl.SYSPAD_DAM_TAG_UPDATE && window.location.href.includes("dam");
    }

    @Input() mode: string = "gridItem";
    @Input() poppedUp: boolean = false; // Used with dataview pop up to let to select extension

    externalLinks;
    externalText = "";

    constructor(
        private sanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _router: Router,
        private thumbnailsService: ThumbnailsService,
        private _swalModal: SwalModalService,
        public translate: TranslateService,
        private _uploadService: UploadService,
        private _swalTargets: SwalPortalTargets,
        private _toastService: ToastService,
        private _fb: FormBuilder,
        private _tagService: TagService,
        private _damService: DataModelService,
        private _mediaService: MediaService,
        private _userService: UserService,
        private _thumbnailsService: ThumbnailsService
    ) {}

    ngOnInit() {
        this.acl = this._userService.getUserAclFromToken();

        this._uploadService.acceptList.subscribe((acceptList) => (this.acceptList = acceptList));

        if (("pim" === this.module && !this.acl.SYSPAD_PIM_MEDIA_READ) || ("dam" === this.module && !this.acl.SYSPAD_DAM_MEDIA_READ)) {
            this.buttons.preview = false;
        }

        if ("pim" === this.module && !this.acl.SYSPAD_PIM_MEDIA_DELETE) {
            this.buttons.unlink = false;
        }

        if ("dam" === this.module && !this.acl.MAESTRO_DAM_MEDIA_UPDATE) {
            this.buttons.crop = false;
            this.buttons.image = false;
            this.buttons.fill = false;
        }

        this.formAddTag = this._fb.group({
            tag: [[], Validators.required],
        });

        this.dataModelForm = this._fb.group({
            dm: [[this.media.model], Validators.required],
        });

        this.damUrl = environment.damUrl;

        if (!this.media.id && this.media.link && this.media.loadMetadata) {
            this._thumbnailsService.getUrlMetadata(this.media.link).subscribe((metadata) => {
                if (metadata.thumbPath.length) {
                    this.media.originalLink = this.media.link; // Keep original link to save in db instead of metadata link
                    this.media.link = this.media.thumbPath = metadata.thumbPath;
                }

                if (metadata.name.length) {
                    this.media.name = metadata.name;
                }
            });
        }
    }

    /**
     * Get the base64 image
     * @param param0
     * @returns
     */
    public getThumb(thumb: { binImage: any }) {
        return this.thumbnailsService.getThumb(thumb);
    }

    /**
     * Open the preview
     */
    open(): void {
        this.openModal.emit(this.media);
    }

    /**
     * Redirect to the model creation
     *
     * @param id
     */
    createMedia(id: number): void {
        this.dataModelModal.fire().then((result) => {
            if (result.value) {
                if (this.dataModelForm.value.dm.length > 0) {
                    this._damService.setModel(id, this.dataModelForm.value.dm[0].id).subscribe(() => {
                        this.media.model = this.dataModelForm.value.dm[0];
                    });
                } else {
                    this._damService.setModel(id, null).subscribe(() => {
                        this.media.model = null;
                    });
                }
            }
        });
    }

    /**
     * Redirect to the fields edition
     *
     * @param id
     */
    editMedia(id: number): void {
        this._router.navigate([`${MAESTRO_ROUTES.dam.media}/${MAESTRO_ROUTES.dam.editForm}`, id], {
            relativeTo: this._route.parent,
        });
    }

    /**
     * Delete a media
     *
     * @param id
     */
    deleteMedia(id: number): void {
        this._swalModal.deleteWithContent("modal.mediadam.delete").then((result) => {
            if (result.value) {
                this.deleted.emit(id);
            }
        });
    }

    /**
     * Emit the event to unlink a media
     * @param id
     */
    unlinkMedia(id: number): void {
        this._swalModal.delete().then((result) => {
            if (result.value) {
                this.unlinked.emit(id);
            }
        });
    }

    /**
     * Get the color for display the extension
     * @returns
     */
    getIconClass(): string {
        return this.thumbnailsService.getIconClass(this.media.extension);
    }

    /**
     * Update a media
     *
     * @param id
     */
    showPopupChangeMedia(id: number): void {
        this.changeMediaSwal.fire().then((result) => {
            if (result.value) {
                const file = ($("#newFile").get(0) as HTMLInputElement).files.item(0);
                let isPicto = false;

                if (this.acl.MAESTRO_DAM_PICTO_CREATE === 1) {
                    isPicto = $("#picto:checked").length === 1;
                }

                if (file) {
                    this.thumbnailsService
                        .updateMedia(id, file, isPicto)
                        .pipe(map((r: any) => r.data))
                        .subscribe((response) => {
                            this._toastService.show({ message: this.translate.instant("dam.updated"), type: "success" });
                            this.thumbUpdateEvent.emit(id);
                        });
                }
            }
        });
    }

    /**
     * Open tag modal
     */
    openTagModal(media: Thumb): void {
        this.formAddTag.controls.tag.setValue([]);

        this.addTagModal.fire().then((result) => {
            if (result.value) {
                if (this.formAddTag.value.tag[0]) {
                    this._tagService
                        .addTag(
                            media.id,
                            this.formAddTag.value.tag.map((t) => t.id)
                        )
                        .subscribe((response: any) => {
                            this.media.tags = response.data;
                        });
                }
            }
        });
        this.addTagModal.update({
            title: this.translate.instant("modal.addTag", { key: media.name }),
        });
        this.filteredTags = this.tags.filter((t) => !media.tags.map((mt) => mt.id).includes(t.id));
    }

    /**
     * Remove a tag
     */
    removeTag(media: Thumb, tagId: number): void {
        this._tagService.removeTag(media.id, { id: tagId }).subscribe((response: any) => {
            this.media.tags = response.data;
            this.tagRemoved.emit();
        });
    }

    /**
     * Send media by mail
     *
     * @param media
     */
    sendByMail(media: Thumb): void {
        alert("Not implemented yet");
    }

    /**
     * Open the crop modal
     *
     * @param mediaId
     */
    cropMedia(mediaId: number): void {
        this.cropper.openCrop(mediaId);
    }

    /**
     * Create a cropped image
     *
     * @param $event
     */
    croppedMedia($event: any): void {
        let flipH = $event.transform.flipH ? $event.transform.flipH : false;
        let flipV = $event.transform.flipV ? $event.transform.flipV : false;
        let crop = {
            x: $event.croppedImage.cropperPosition.x1,
            y: $event.croppedImage.cropperPosition.y1,
            height: $event.croppedImage.height,
            width: $event.croppedImage.width,
            rotate: $event.rotation,
            flipH: flipH,
            flipV: flipV,
        };
        this._mediaService.createCroppedImage(this.media.id, crop, $event.format).subscribe(() => {});
    }

    /**
     * Check if media exist
     * @param arrayMedias
     * @param mediaId
     * @param isObject
     * @returns
     */
    findMedia(arrayMedias: any[], mediaId: number, isObject: boolean = false): boolean {
        return arrayMedias.find((id) => (isObject ? id.id === mediaId : id === mediaId)) ? true : false;
    }

    selectedMedia(media) {
        this.selectMedia.emit(media);
    }

    openCropMedia(media) {
        this.openCrop.emit(media);
    }
}
