<div *ngIf="!filtered; else elseBlock">
    <!-- DAM & Associative pop up (PIM + Project)-->
    <p-dataView #dv [value]="dataviewContent" [layout]="layout" [lazy]="lazy" (onLazyLoad)="lazyEvent.emit($event)"
        [paginator]="paginator" [rows]="rows" [rowsPerPageOptions]="rowsPerPageOptions" [totalRecords]="totalRecords"
        [sortField]="sortField" [sortOrder]="sortOrder" [filterBy]="filterBy"
        [showCurrentPageReport]="showCurrentPageReport" [currentPageReportTemplate]="'dataview.info' | translate"
        [emptyMessage]="'dataview.emptyView' | translate" [(first)]="first">

        <ng-template pTemplate="header">
            <div class="flex flex-row justify-content-between">
                <!-- Sort fields -->
                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey"
                    placeholder="{{ 'dataview.sortBy' | translate }}" (onChange)="onSortChange($event)"
                    styleClass="mb-2 mb-md-0"></p-dropdown>
                <!-- Mass Tag Action -->
                <div *ngIf="acl && acl.MAESTRO_DAM_MEDIA_UPDATE && !minified && cartLength && filters && filters['filterTags']">
                    <button id="massTag" type="button" data-toggle="modal" data-target="#appTreeTagModal"
                        (click)="massTagOpen()" class="btn btn-secondary mr-2 pi pi-tags"
                        pTooltip="{{ 'massAction.object.tag' | translate }} {{ 'massAction.mass' | translate }}"
                        tooltipPosition="bottom" tooltipZIndex="{{ tooltipZIndex }}"
                        [disabled]="!filters['filterTags'].data.length"></button>
                </div>
                <!-- Search bar -->
                <div *ngIf="searchBar" class="d-md-flex ">
                    <!-- Search bar -->
                    <div  class="w-full">
                        <span class="input-group-lg p-input-icon-right ml-auto w-full">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="w-full form-control" (change)="searchEvent.emit($event.target.value)"
                                    placeholder="{{ 'dataview.search' | translate }} {{ 'dataview.searchData.' + searchDataParameter | translate }}" />

                        </span>
                    </div>
                </div>

                <!-- filter expire media-->
                <div  class="row justify-content-center">
                    <div class="text-left ml-2 mt-1">
                        <!-- <label class="switch switch-label switch-outline-primary">
                            <input class="switch-input" type="checkbox" (change)="filterExpireMedia.emit($event.target.checked)"/>
                            <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>  
                        </label> -->
                        <p-dropdown [options]="state" [(ngModel)]="expireKey"
                            placeholder="{{ 'filter.state' | translate }}" (onChange)="filterExpireMedia.emit($event.value)"
                            styleClass="mb-2 mb-md-0"></p-dropdown>
                    </div>
                </div>


                <!-- Display mode buttons -->
                <p-dataViewLayoutOptions *ngIf="!minified" p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
        </ng-template>

        <ng-template let-dataview pTemplate="gridItem">
            <div app-thumb mode="gridItem" [media]="dataview" [dataModels]="dataModels" [tags]="allTags"
                [buttons]="buttons" [selectable]="selectable" [selectedMedias]="selectedMedias"
                [linkedMedias]="linkedMedias" (tagFiltered)="tagFiltered.emit($event)"
                (unlinked)="unlinked.emit($event)" (openModal)="!minified ? openModal($event) : null"
                (addCart)="addCart.emit($event)" (deleted)="deleted.emit($event)"
                (thumbUpdateEvent)="thumbUpdateEvent.emit($event)" (selectMedia)="selectMedia.emit($event)"
                (selectExtension)="selectExtension.emit($event)"
                (openCrop)="openCrop.emit($event)"
                [poppedUp]="poppedUp"
                class="d-inline-block col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-1" [singleSelection]="singleSelection" [linkAdded]="linkAdded">
            </div>
        </ng-template>

        <ng-template *ngIf="!minified" let-dataview pTemplate="listItem">
            <div app-thumb mode="listItem" [media]="dataview" [dataModels]="dataModels" [tags]="allTags"
                [buttons]="buttons" (tagFiltered)="tagFiltered.emit($event)" (unlinked)="unlinked.emit($event)"
                (openModal)="openModal($event)" (addCart)="addCart.emit($event)" (deleted)="deleted.emit($event)"
                (thumbUpdateEvent)="thumbUpdateEvent.emit($event)" class="col-12" [singleSelection]="singleSelection" [linkAdded]="linkAdded">
            </div>
        </ng-template>
    </p-dataView>
</div>
<ng-template #elseBlock>
    <!-- PIM linked media display -->
    <div *ngFor="let filter of mediasFilter | keyvalue:onCompare">
        <div *ngIf="filter.value.length" class="col-12">
            <div class="mb-5">
                <p-dataView #dvp [value]="filter.value" [paginator]="paginator" [rows]="6" [layout]="layout"
                    [totalRecords]="filter.value.length" [showCurrentPageReport]="showCurrentPageReport"
                    [currentPageReportTemplate]="'dataview.info' | translate" filterBy="name">

                    <ng-template pTemplate="header">
                        <div class="flex flex-row justify-content-between">
                            <span class="text-primary align-self-center">{{ titleNotTooltip + '.' + filter.key | translate }}</span>&nbsp;
                            <app-order-data [data]="filter.value" module="dam" [key]="filter.key" (reorderMedia)="reorderMediaPosition($event)"></app-order-data><!-- @TODO: See why display same data even if different in component -->
                            <!-- Search bar -->
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input class="pl-4" pInputText type="text" (input)="dvp.filter($event.target.value)"
                                    placeholder="{{ 'dataview.research' | translate }} {{ 'dataview.searchData.' + filter.key | translate }}" />
                            </span>
                        </div>
                    </ng-template>

                    <ng-template let-value pTemplate="gridItem">
                        <div app-thumb mode="gridItem" [media]="value" [buttons]="buttons" [minified]="true"
                            (unlinked)="unlinked.emit($event)" (openModal)="openModal($event)" module="pim"
                            class="d-inline-block col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-1">
                        </div>
                    </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>
</ng-template>

<app-preview [dataModels]="dataModels" [tags]="allTags" [buttons]="buttons" (tagRemoved)="tagRemoved.emit()"
    (next)="nextMedia()" (previous)="previousMedia()" (unlinked)="unlinked.emit($event)"
    (addCart)="addCart.emit($event)" (deleted)="deleted.emit($event)">
</app-preview>

<!-- @TODO : Add if on lazy to switch dataView structure ? -->

<!-- Selection massTag -->
<app-treeTag *ngIf="filters.hasOwnProperty('filterTags')" #treeTag [dataLength]="cartLength" [value]="filters['filterTags'].data"
    (massTagStart)="massTagSelection($event)">
</app-treeTag>
