import { Component, OnDestroy, OnInit } from "@angular/core";
import { faIndent, faList, faTag, faTasks } from "@fortawesome/free-solid-svg-icons";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { TagService } from "app/core/services/tag/tag.service";
import { MenuItems } from "app/shared/components/breadcrumb";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES as routes } from "../../shared/routes/index";

@Component({
    selector: "app-pim",
    templateUrl: "./pim.component.html",
})
export class PimComponent implements OnInit, OnDestroy {
    private readonly _baseRoute = `/${routes.pim.base}/`;

    constructor(private _layout: LayoutService, private _tagService: TagService, private _userService: UserService) {}

    ngOnInit(): void {
        this._layout.menu.items = this.createMenuItems();
        this._tagService.setModulePath("pim");
        this._layout.breadcrumb.setPath({ routerLink: `/${routes.pim.base}`, name: "breadcrumb.pim.home" }, 0);
    }

    ngOnDestroy(): void {
        this._layout.menu.items = null;
        this._layout.breadcrumb.setPath(null, 0);
    }

    /**
     * Create cog items
     * @returns
     */
    private createMenuItems(): MenuItems {
        const items = [];
        const acl: ACL = this._userService.getUserAclFromToken();
        if (acl.SYSPAD_PIM_ELEMENT_LIST) {
            items.push({
                icon: faIndent,
                routerLink: this._baseRoute + routes.pim.element,
                text: "pim.element.title",
            });
        }
        if (acl.SYSPAD_PIM_ELEMENTTYPE_LIST) {
            items.push({
                icon: faIndent,
                routerLink: this._baseRoute + routes.pim.elementType,
                text: "pim.elementTypes.title",
            });
        }
        if (acl.SYSPAD_PIM_LIST_LIST) {
            items.push({
                icon: faList,
                routerLink: this._baseRoute + routes.pim.list,
                text: "pim.lists.title",
            });
        }
        if (acl.SYSPAD_PIM_FIELDSET_LIST) {
            items.push({
                icon: faTasks,
                routerLink: this._baseRoute + routes.pim.fieldset,
                text: "pim.fieldsets.title",
            });
        }
        if (acl.SYSPAD_PIM_TAG_LIST) {
            items.push({
                icon: faTag,
                routerLink: this._baseRoute + routes.pim.tag,
                text: "tags.title",
            });
        }
        return items;
    }
}
