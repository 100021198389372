import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faEdit, faEye, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faImage, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { BreadcrumbFlatplanToolbarService } from "app/core/services/global/layout/bread-crumb-flatplan-toolbar.service";
import { PageOptionTabService } from "app/core/services/project/export/page-option-tab.service";
import { MaestroPage } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { ThumbnailItemEvent, ThumbnailMenu, ThumbnailMenuAction } from "app/shared/models/project/thumbnail";
import { ProjectExportService } from "../../../../../../../../../core/services/project/export/export.service";
import { PageThumbnailService } from "../../../../../../../../../core/services/project/export/page-thumbnail.service";
import { FlatplanViewType } from "../../../../../../../../../shared/models/project/bread-crumb-flatplan-toolbar";

@Component({
    selector: "app-page-thumbnail",
    templateUrl: "./page-thumbnail.component.html",
    styleUrls: ["./page-thumbnail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PageThumbnailComponent implements OnInit, AfterViewChecked {
    @Input() page: MaestroPage;
    @Input() flatplanView: FlatplanViewType;
    @Input() exist: number;
    @Output() menuAction: EventEmitter<ThumbnailItemEvent> = new EventEmitter();

    @ViewChild("nav") tab: NgbNav;

    FlatplanViewType = FlatplanViewType;

    previewUrl: any = null;

    projectId: number;
    exportId: number;
    object: string;

    imageTabOver = false;
    mouseOver = false;
    imageHeight = "166px";
    imageThumbnail = null;

    pageMenu: ThumbnailMenu = [];



    readonly faInfoCircle = faInfoCircle;
    readonly faPlusSquare = faPlusSquare;
    readonly faDownload = faDownload;
    readonly faImage = faImage;
    readonly faEye = faEye;
    readonly faEdit = faEdit;
    sub: any = null;
    enableTabOver: boolean;
    acl: ACL;

    constructor(
        private _authService: AuthService,
        private _change: ChangeDetectorRef,
        private _toolbar: BreadcrumbFlatplanToolbarService,
        private _pageThumbnailService: PageThumbnailService,
        private _translate: TranslateService,
        private _exportService: ProjectExportService,
        private _sanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _pageOptionService: PageOptionTabService
    ) {
        this.flatplanView = this._toolbar.flatplanView;
        
    }

    @HostListener("mouseenter") onEnter() {
        this.mouseOver = true;
    }
    @HostListener("mouseleave") onLeave() {
        this.mouseOver = false;
    }

    ngOnInit() {
        if(this.page.elementLength == 0 && this.page.templateId == null){
            this.pageMenu = [
                { title: this._translate.instant("projects.flatplan.importPDF"), action: ThumbnailMenuAction.ImportPDF },
                { title: this._translate.instant("projects.flatplan.configurePage"), action: ThumbnailMenuAction.UpdatePage },
                { title: this._translate.instant("projects.flatplan.deletePage"), action: ThumbnailMenuAction.PageDelete },
            ];
        } else {
            this.pageMenu = [
                { title: this._translate.instant("projects.flatplan.configurePage"), action: ThumbnailMenuAction.UpdatePage },
                { title: this._translate.instant("projects.flatplan.deletePage"), action: ThumbnailMenuAction.PageDelete },
            ];
        }

        
        this.acl = this._authService.getUserAclFromToken();

        this._pageThumbnailService.currentHeading$.subscribe((head) => {
            this.imageTabOver = head === this.page.heading;
            this._change.markForCheck();
        });
        this._pageThumbnailService.enableMouseOver$.subscribe((value) => {
            this.enableTabOver = value;
            this._change.markForCheck();
        });

        this.exportId = this._route.parent.snapshot.params.id;
        this.projectId = this._route.parent.parent.parent.parent.snapshot.params.id;

        this._exportService.pagesPreview.subscribe((previews) => {
            const previewUrl = previews.filter((p) => p.pageId === this.page.id && p.index === this.page.index)[0]?.preview || null;
            if (previewUrl) {
                this.previewUrl = previewUrl.hasOwnProperty("changingThisBreaksApplicationSecurity") ? previewUrl["changingThisBreaksApplicationSecurity"] : previewUrl;

                // Préchargement de l'image en arrière-plan
                const imageThumbnail = new Image();
                imageThumbnail.src = this.previewUrl;
                imageThumbnail.addEventListener("load", () => {
                    // L'image est prête, mettez à jour la variable
                    this.imageThumbnail = imageThumbnail;
                    this._change.markForCheck();
                });
            }
        });

        
    }

    getPreview(){
        
        this._exportService.pagesPreview.subscribe((previews) => {
            const previewUrl = previews.filter((p) => p.pageId === this.page.id && p.index === this.page.index)[0]?.preview || null;

            if (previewUrl) {
                this.previewUrl = previewUrl.hasOwnProperty("changingThisBreaksApplicationSecurity") ? previewUrl["changingThisBreaksApplicationSecurity"] : previewUrl;
                this.page.isImport = true;
                // Préchargement de l'image en arrière-plan
                const imageThumbnail = new Image();
                imageThumbnail.src = this.previewUrl;
                imageThumbnail.addEventListener("load", () => {
                    // L'image est prête, mettez à jour la variable
                    
                    this._change.markForCheck();
                });
            }
        });
    }

    isTemplateExist(): boolean {
        return (this.page.template && this.page.template.name !== "") || this.page.isImport;
    }

    isProductsExist(): boolean {
        return (this.page.elements && this.page.elements.length !== 0) || this.page.isImport;
    }

    ngAfterViewChecked(): void {
        if (!this.sub) {
            this.sub = this._toolbar.flatplanView$.subscribe((view) => {
                this.tab.select("flatplanView." + view + "-id." + this.page.id);
                this._change.markForCheck();
            });
        }
    }

    onMenuClick(action: ThumbnailMenuAction) {
        this.emitAction(action);
    }

    onProductAdd() {
        this.emitAction(ThumbnailMenuAction.ElementsEdit);
    }
    onPageEdit() {
        this.emitAction(ThumbnailMenuAction.Edit);
    }

    onPreview() {
        this.emitAction(ThumbnailMenuAction.Preview);
    }

    private emitAction(action: ThumbnailMenuAction, data: any = this.page) {
        data.nbpage = data.pageLength;
        this.menuAction.emit({ action, data });
    }

    downloadFile(fileName: string, data: any): void {
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}
