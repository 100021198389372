<div *ngIf="media" class="card mb-2 p-0">
    <div *ngIf="!preview" class="card-header text-center d-flex">
        <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ media.name }}</h4>
    </div>

    <app-edit-base-form [mediaId]="media.id" [copyright]="copyright" [legend]="legend" [dateValid]="dateValid"></app-edit-base-form>

    <div class="card-body">
        <div *ngFor="let field of media.fields" class="row mb-1">
            <div class="col-11 form-group d-flex p-0">
                <label [ngbTooltip]="field.fieldName" class="col-3 col-form-label font-weight-bold text-truncate">{{ field.fieldName }}</label>
                <input [disabled]="field.isLoading" (change)="saveField(field, $event.target.value)" class="form-control col-9" [value]="field.fieldValue ? field.fieldValue.value : null" *ngIf="field.fieldType === 'text' || field.fieldType === 'number'" type="{{ field.fieldType }}" step="0.01">
                <label class="switch switch-pill switch-primary" *ngIf="field.fieldType === 'checkbox'">
                    <input [disabled]="field.isLoading" (change)="saveField(field, $event.target.checked)" type="checkbox" class="switch-input" [checked]="field.fieldValue ? field.fieldValue.value === '1' : false">
                    <span class="switch-slider"></span>
                </label>
                <app-date-picker [active]="!field.isLoading" (dateSelect)="saveField(field, $event)" class="col-9 p-0" [minDate]="{ year: 1930, month: 1, day: 1 }" [value]="field.fieldValue ? field.fieldValue.value : null" *ngIf="field.fieldType === 'date'"></app-date-picker>

            </div>
            <div class="col-1 p-0 text-center" title="{{ 'tooltip.history' | translate }}">
                <svg (click)="openHistory(field.fieldValue.id)"  *ngIf="!field.isLoading && (field.fieldValue && field.fieldValue.id && field.fieldValue.value !== null)" style="width: 1.1rem; height: 1.1rem;" class="mt-2 pointer mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <div *ngIf="field.isLoading" class="spinner-border mx-auto text-primary spinner-border-sm align-middle" style="vertical-align: -webkit-baseline-middle !important;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>

<swal #historyModal [showConfirmButton]="false" title="{{ 'history.title' | translate }}">
    <div *swalPortal>
        <div class="row justify-content-center">
            <label class="switch switch-label switch-outline-primary-alt">
                <input [checked]="showDiff" class="switch-input" type="checkbox" (change)="switchDiff()" />
                <span class="switch-slider" data-checked="Diff" data-unchecked="Diff"></span>
            </label>
        </div>

        <div *ngFor="let modification of history; let i = index" class="row">
            <div class="col-12 mb-3">
                <div class="row" *ngFor="let values of modification.value">
                    <div class="col-10">
                        <div class="text-center border p-1">
                            <span *ngIf="showDiff === false || !values.oldValue.value" [innerHtml]="sanitize(values.newValue.value)"></span>
                            <span *ngIf="showDiff === true && values.oldValue.value" diff [left]="values.oldValue.value" [right]="values.newValue.value"></span>
                        </div>
                        <span class="mt-1 font-weight-bold">{{ modification.nameUser }} : {{ modification.date }}</span>
                    </div>
                    <div class="col-2">
                        <button data-toggle="popover" [id]="'tooltip-' + i" (click)="copyToClip(values.newValue.value, i)" data-placement="top" title="{{ 'tooltip.copied' | translate }}" class="btn btn-secondary">
                            <fa-icon [icon]="faClone"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</swal>