<ng-container *ngIf="data.elementValue !== undefined || data.elementValue !== null">
    <ul *ngFor="let value of data.elementValue" [ngClass]="{ 'list-unstyled': data.elementValue.length === 1 }">
        <li *ngIf="data.fieldType !== 'list' && data.fieldType !== 'table'"
            [innerHTML]="value.value || 'Non renseigné'"></li>
        <div *ngIf="data.fieldType === 'list'">
            <li *ngFor="let row of value.value " [innerHTML]="row.name || 'Non renseigné'"></li>
        </div>
        <div *ngIf="data.fieldType === 'table'">
            <app-form-table [id]="data.id" [cells]="value.value" [overlayPanelMode]="true">
            </app-form-table>
        </div>
    </ul>

    <div *ngIf="!data.elementValue && data.collections.length === 0">
        <ul class="list-unstyled">
            <li>Non renseigné</li>
        </ul>
    </div>
</ng-container>
<ng-container *ngIf="data.collections !== undefined">
    <div *ngFor="let collection of data.collections">
        <ng-container *ngIf="collection.elementValueView !== undefined">
            <ul *ngFor="let value of collection.elementValueView"
                [ngClass]="{ 'list-unstyled': collection.elementValueView.length === 1 }">
                <li *ngIf="value.fieldType !== 'table'">
                    <b>{{ value.input }} : </b><span [innerHTML]='value.value || "Non renseigné"'></span>
                </li>
                <div *ngIf="value.fieldType === 'table'">
                    <b>{{ value.input }} : </b>
                    <app-form-table [id]="value.id" [cells]="value.value" [overlayPanelMode]="true">
                    </app-form-table>
                </div>
            </ul>
        </ng-container>
        <hr />
    </div>
</ng-container>