import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MediaService } from "app/core/services/dam/media.service";
import { ToastService } from "app/core/services/global/toast/toast.service";

@Component({
    selector: "app-edit-base-form",
    templateUrl: "./edit-base-form.component.html",
})
export class EditBaseFormComponent implements OnInit {
    @Input() copyright: string;
    @Input() legend: string;
    @Input() dateValid: string;
    @Input() mediaId: number;

    form: FormGroup;

    oldValues = {
        copyright: "",
        legend: "",
        dateValid: "",
    };

    constructor(private formBuilder: FormBuilder, private mediaService: MediaService, private _toaster: ToastService, private _translate: TranslateService) {}

    ngOnInit(): void {
        this.oldValues = { copyright: this.copyright, legend: this.legend, dateValid: this.dateValid };
        this.initForm();
    }

    initForm() {
        this.form = this.formBuilder.group({
            copyright: this.copyright,
            legend: this.legend,
            dateValid: this.dateValid,
        });
    }

    saveField() {
        let value = this.form.value;
        this.mediaService.saveBaseField(this.mediaId, value.copyright, value.legend, value.dateValid).subscribe(() => {
            let fieldName;

            if (value.copyright != this.oldValues.copyright) {
                fieldName = this._translate.instant("dam.metadata.copyright");
            } else if (value.legend != this.oldValues.legend) {
                fieldName = this._translate.instant("dam.legend");
            } else if (value.dateValid != this.oldValues.dateValid) {
                fieldName = this._translate.instant("dam.dateValid");
            }

            if (undefined !== fieldName) {
                this._toaster.show({ message: this._translate.instant("dam.model.savedField", { key: fieldName }), type: "success" });
            }

            this.oldValues = { copyright: value.copyright, legend: value.legend, dateValid: value.dateValid };
        });
    }
}
