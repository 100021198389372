import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { faChevronCircleDown, faChevronCircleUp, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "app/core/services/global/data/data.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { TableService } from "app/core/services/global/table/table.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
    selector: "app-section-tab",
    templateUrl: "./section-tab.component.html",
    styleUrls: ["./section-tab.component.scss"],
})
export class SectionTabComponent implements OnInit {
    @Input() sections: { [key: string]: any[] };
    @Input() setValue: boolean;

    @Output() onSaveField = new EventEmitter<{ field: any; payload: { collectionId: number; value: any; valueId: number; inputId: number }; inputFieldset: any }>();
    @Output() onHistoryClick = new EventEmitter<any>();
    @Output() onAddCollection = new EventEmitter<{ field: any; payload: any /*{ collectionId: number; value: any; valueId: number; inputId: number }*/; duplicateFieldsetData: boolean }>();
    @Output() onRemoveCollection = new EventEmitter<{ field: any; collectionId: number }>();
    @Output() onSwitchCollections = new EventEmitter<{ collectionIds: number[]; field: any }>();

    sectionsNames: string[];
    importTableDisabled: boolean = false;
    underline: boolean = false;
    currentSection: string;
    currentField: number;
    currentCollection: number;
    currentInput: number;
    currentCollectionFieldset: number;
    currentInputCollection: number;
    currentCollections: any;
    currentFieldValueInput: number;
    displaySortableStates: any[] = [];

    arraySelection: {}[] = [];

    @ViewChild("nav") nav: NgbNav;

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        itemsShowLimit: 6,
        allowSearchFilter: true,
        enableCheckAll: true,
        selectAllText: this._translate.instant("general.select_all"),
        unSelectAllText: this._translate.instant("general.unselect_all"),
        searchPlaceholderText: this._translate.instant("general.search"),
        noDataAvailablePlaceholderText: this._translate.instant("general.no.data"),
    };

    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected",
        direction: "horizontal",
        filter: "not-sortable",
        onMove: function (event: any) {
            return event.related.className.indexOf("not-sortable") === -1;
        },
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const id = Number(event.srcElement.id.split("-")[1]);
            const sectionName = event.srcElement.id.split("-")[2];

            let field = this.sections[sectionName].find((f) => f.id === id);

            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(field.collections, from, to);
            this.switchPosition(
                field.collections.map((c) => c.id),
                field
            );
        }.bind(this),
    };

    readonly sortableJSOptionsLvl2 = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected",
        direction: "horizontal",
        filter: "not-sortable",
        onMove: function (event: any) {
            return event.related.className.indexOf("not-sortable") === -1;
        },
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const fieldId = Number(event.srcElement.id.split("-")[1]);
            const sectionName = event.srcElement.id.split("-")[2];
            const collectionId = Number(event.srcElement.id.split("-")[3]);
            const inputId = Number(event.srcElement.id.split("-")[4]);

            let indexField = this.sections[sectionName].map((fieldSection) => fieldSection.id).indexOf(fieldId);
            let indexCollection = this.sections[sectionName][indexField].collections.map((collectionSection) => collectionSection.id).indexOf(collectionId);
            let indexInput = this.sections[sectionName][indexField].collections[indexCollection].elementValueView.map((inputSection) => inputSection.id).indexOf(inputId);
            let collections =
                this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value !== null
                    ? this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value
                    : [];

            let field = this.sections[sectionName][indexField];
            let input = this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput];

            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(collections, from, to);

            let result = JSON.stringify(collections);

            this.saveCollectionField(field, collectionId, input.id, input.inputId, result, indexField, indexCollection, indexInput);
        }.bind(this),
    };

    readonly sortablejsOptionsItems = {
        multiDrag: false, // Enable multi-drag
        selectedClass: "selected",
        fallbackTolerance: 3,
        animation: 150,
        filter: ".not-sortable",
        draggable: ".item-selection",
        onEnd: (event: any) => {
            function array_move(arr: any[], oldIndex: number, newIndex: number) {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
                return arr;
            }

            const from = event.oldIndex;
            const to = event.newIndex;
            if (from != to) {
                const newPositionListValue = this.sections[this.currentSection][this.currentField].elementValue[0].value;
                const field = this.sections[this.currentSection][this.currentField];
                let newTab = array_move(newPositionListValue, from, to);
                const oldPositionListValue = [...newTab]; // Create a copy for comparison*
                this.sections[this.currentSection][this.currentField].elementValue[0].value = [];
                newTab.forEach((item) => {
                    this.sections[this.currentSection][this.currentField].elementValue[0].value.push(item);
                });
                this.saveField(field, newPositionListValue);
            }
        },
    };

    readonly sortablejsOptionsFieldset = {
        multiDrag: false, // Enable multi-drag
        selectedClass: "selected",
        fallbackTolerance: 3,
        animation: 150,
        filter: ".not-sortable",
        draggable: ".item-selection",
        onEnd: (event: any) => {
            function array_move(arr: any[], oldIndex: number, newIndex: number) {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
                return arr;
            }

            const from = event.oldIndex;
            const to = event.newIndex;
            if (from != to) {
                const field = this.sections[this.currentSection][this.currentField].collections[this.currentCollection].elementValueView[this.currentInput];
                const newPositionListValue = this.sections[this.currentSection][this.currentField].collections[this.currentCollection].elementValueView[this.currentInput].value;
                let newTab = array_move(newPositionListValue, from, to);
                this.sections[this.currentSection][this.currentField].collections[this.currentCollection].elementValueView[this.currentInput].value = [];
                newTab.forEach((item) => {
                    this.sections[this.currentSection][this.currentField].collections[this.currentCollection].elementValueView[this.currentInput].value.push(item);
                });
                const collectionId = this.sections[this.currentSection][this.currentField].collections[this.currentCollection].id;
                this.saveCollectionField(field, collectionId, field.id, field.inputId, newPositionListValue);
            }
        },
    };

    sortablejsOptionsFieldsetValueInput = {
        multiDrag: false, // Enable multi-drag
        selectedClass: "selected",
        fallbackTolerance: 3,
        animation: 150,
        filter: ".not-sortable",
        draggable: ".item-selection",
        onEnd: (event: any) => {
            function array_move(arr: any[], oldIndex: number, newIndex: number) {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
                return arr;
            }

            const from = event.oldIndex;
            const to = event.newIndex;
            if (from != to) {
                const field = this.currentFieldValueInput;
                const newPositionListValue = this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue;
                const input = field["collections"][this.currentCollection].elementValueView[this.currentInput];
                let newTab = array_move(newPositionListValue, from, to);
                this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue = [];
                newTab.forEach((item) => {
                    this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue.push(item);
                });
                this.saveValueInput(
                    this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue,
                    field,
                    this.currentCollectionFieldset,
                    this.currentInputCollection,
                    input,
                    this.currentCollections
                );
            }
        },
    };

    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrash;
    readonly faChevronCircleDown = faChevronCircleDown;
    readonly faChevronCircleUp = faChevronCircleUp;
    private _currentSections: any;

    currentTable: string = ""; // Table in JSON
    currentTitle: string = "";
    currentFieldId: number = -1;
    currentCollectionId: number | null;
    currentInputId: number | null;
    currentCollectionBisId: number | null;
    currentInputBisId: number | null;
    tableSources: string[] = ["model"];
    displayAll: any[] = [];
    displaySourceSelection: boolean = false; // Display modal to select source
    fieldsetSources = {}; // List of fieldsets where we can get data to duplicate
    selectedSource = "null"; // Selected fieldset to duplicate value
    contextFieldset = null; // Fieldset where we click on add collection button
    contextSubdata = null; // Fieldset's subdata where we click on add collection button
    isCollectionInput: boolean = false;

    constructor(private _translate: TranslateService, private _modal: SwalModalService, private _tableService: TableService, private cdRef: ChangeDetectorRef, private _dataService: DataService) {
        this.sectionsNames = [];
    }

    ngOnInit(): void {}

    /**
     *
     * @param sectionName
     * @param indexField
     * @param collection
     * @param input
     * @param collectionFieldset
     * @param inputCollection
     * @param collections
     * @param field
     * error : core.js:6210 ERROR TypeError: Cannot create property '0' on boolean 'false'
     */
    displaySortable(sectionName: string, indexField: number, collection = null, input = null, collectionFieldset = null, inputCollection = null, collections = null, field = null): void {
        this.currentSection = sectionName;
        this.currentField = indexField;
        this.currentCollection = collection;
        this.currentInput = input;
        this.currentCollectionFieldset = collectionFieldset;
        this.currentInputCollection = inputCollection;
        this.currentFieldValueInput = field;
        this.currentCollections = collections;

        this.verifyDisplaySortable(indexField, collection, input, collectionFieldset, inputCollection);
        if (collection !== null && input !== null) {
            if (!this.displaySortableStates[indexField]) {
                this.displaySortableStates[indexField] = [];
            }
            if (!this.displaySortableStates[indexField][collection]) {
                this.displaySortableStates[indexField][collection] = [];
            }

            if (collectionFieldset !== null && inputCollection !== null) {
                if (!this.displaySortableStates[indexField][collection][input]) {
                    this.displaySortableStates[indexField][collection][input] = [];
                }
                if (!this.displaySortableStates[indexField][collection][input][collectionFieldset]) {
                    this.displaySortableStates[indexField][collection][input][collectionFieldset] = [];
                }
                this.displaySortableStates[indexField][collection][input][collectionFieldset][inputCollection] =
                    !this.displaySortableStates[indexField][collection][input][collectionFieldset][inputCollection];
            } else {
                this.displaySortableStates[indexField][collection][input] = !this.displaySortableStates[indexField][collection][input];
            }
        } else {
            this.displaySortableStates[indexField] = !this.displaySortableStates[indexField];
        }
    }

    verifyDisplaySortable(indexField, collection, input, collectionFieldset, inputCollection) {
        if (this.displaySortableStates[indexField]) {
            if (collection !== null && input !== null) {
                if (this.displaySortableStates[indexField][collection]) {
                    if (collectionFieldset !== null && inputCollection !== null) {
                        if (this.displaySortableStates[indexField][collection][input] && this.displaySortableStates[indexField][collection][input][collectionFieldset]) {
                            if (!this.displaySortableStates[indexField][collection][input][collectionFieldset][inputCollection]) {
                                this.displaySortableStates = [];
                            }
                        } else {
                            this.displaySortableStates = [];
                        }
                    } else {
                        if (!this.displaySortableStates[indexField][collection][input]) {
                            this.displaySortableStates = [];
                        }
                    }
                }
            } else {
                if (!this.displaySortableStates[indexField]) {
                    this.displaySortableStates = [];
                }
            }
        } else {
            this.displaySortableStates = [];
        }
    }

    deleteListValue(sectionName: string, indexField: number, position: number): void {
        const field = this.sections[this.currentSection][this.currentField];
        const positionListValue = this.sections[sectionName][indexField].elementValue[0].value[position];
        const newPositionValue = this.sections[sectionName][indexField].elementValue[0].value.filter((items): boolean => items.id !== positionListValue.id);
        this.saveField(field, newPositionValue);
    }

    deleteOptionsFieldset(position, collectionId) {
        const field = this.sections[this.currentSection][this.currentField].collections[0].elementValueView[this.currentInput];
        const positionListValue = this.sections[this.currentSection][this.currentField].collections[0].elementValueView[this.currentInput].value[position];
        const newPositionValue = this.sections[this.currentSection][this.currentField].collections[0].elementValueView[this.currentInput].value.filter(
            (item: any): boolean => item.id !== positionListValue.id
        );
        this.saveCollectionField(field, collectionId, field.id, field.inputId, newPositionValue);
    }

    deleteOptionsValueInput(position: number): void {
        const field = this.currentFieldValueInput;

        const input = field["collections"][this.currentCollectionFieldset].elementValueView[this.currentInput];
        const positionListValue = this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue[position];
        const newPositionListValue = this.currentCollections[this.currentCollectionFieldset][this.currentInputCollection].elementValue.filter((item: any): boolean => item.id !== positionListValue.id);
        this.saveValueInput(newPositionListValue, field, this.currentCollectionFieldset, this.currentInputCollection, input, this.currentCollections);
    }

    ngAfterContentChecked(): void {
        if (this._currentSections !== this.sections) {
            this.initDisplayProductLink();
            setTimeout(() => this.nav.select(0));
        }
    }

    initDisplayProductLink() {
        this.sectionsNames = Object.keys(this.sections);
        this._currentSections = this.sections;
        this.configProductLinkDisplay(this._currentSections, this.sectionsNames);
    }

    /**
     * Emit event to save a field
     *
     * @param field
     * @param value
     */
    saveField(field: any, value: any): void {
        this.arraySelection = value;
        this.onSaveField.emit({
            field: field,
            payload: {
                collectionId: -1,
                inputId: field.id,
                valueId: field.elementValue && field.elementValue[0] ? field.elementValue[0].id : -1,
                value: typeof value === "string" ? value.trim() : value,
            },
            inputFieldset: null,
        });
    }

    /**
     * Emit event to save a collection
     *
     * @param field
     * @param collectionId
     * @param valueId
     * @param inputId
     * @param value
     * @param indexField
     * @param indexCollection
     * @param indexInput
     */
    saveCollectionField(field: any, collectionId: any, valueId: number, inputId: number, value: any, indexField: any = null, indexCollection: any = null, indexInput: any = null): void {
        this.onSaveField.emit({
            field: field,
            payload: {
                collectionId: collectionId,
                inputId: inputId,
                valueId: valueId,
                value: typeof value === "string" ? value.trim() : value,
            },
            inputFieldset:
                indexField != null
                    ? {
                          indexField: indexField,
                          indexCollection: indexCollection,
                          indexInput: indexInput,
                      }
                    : null,
        });
    }

    /**
     * Emit event to save a table
     * @param sectionFields
     * @param oldValue
     * @param newValue
     * @param sectionName
     */
    saveTable(sectionFields: any, oldValue: any, newValue: any, sectionName: string = ""): void {
        let field;

        if (sectionFields && sectionFields.length) {
            field = this.findField(sectionFields);

            if (field) {
                if (typeof oldValue !== "string" && oldValue !== null) {
                    oldValue = JSON.stringify(oldValue);
                }
                const val = null === oldValue ? "" : oldValue;
                if (typeof val.localeCompare !== "undefined" && 0 !== val.localeCompare(newValue)) {
                    if (field.hasOwnProperty("collections") && field.collections.length && this.currentCollectionId && this.currentInputId) {
                        const elementValue = this.findElementValue(field);
                        if (elementValue) {
                            if (elementValue.fieldType === "fieldset") {
                                this.saveValueInput(newValue, field, this.currentCollectionBisId, this.currentInputBisId, elementValue, elementValue.value, sectionName);
                            } else {
                                this.saveCollectionField(elementValue, this.currentCollectionId, elementValue.id, this.currentInputId, JSON.stringify(newValue));
                            }
                        }
                    } else {
                        this.saveField(field, JSON.stringify(newValue));
                    }

                    this.currentTable = newValue;
                }
            }
        }
    }

    /**
     * Emit event to open history
     *
     * @param inputId
     * @param fieldType
     */
    openHistory(inputId: number, fieldType: string): void {
        const data = {
            inputId: inputId,
            fieldType: fieldType,
        };

        this.onHistoryClick.emit(data);
    }

    /**
     * Emit event to add collection
     *
     * @param field
     */
    addCollection(field: any): void {
        const payload = {
            collectionId: -1,
            inputId: field.id,
            valueId: -1,
            value: null,
        };
        this.onAddCollection.emit({ field: field, payload: payload, duplicateFieldsetData: false });
        // setTimeout(() => nav.select(nav.items._results.length));

        // event.preventDefault();
    }

    /**
     * Emit event to remove a collection from a fieldset
     *
     * @param event
     * @param field
     * @param idCollection
     */
    removeCollection(event: MouseEvent, field: any, idCollection: any): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                this.onRemoveCollection.emit({ field: field, collectionId: idCollection });
            }
        });
        event.preventDefault();
        event.stopImmediatePropagation();
    }

    /**
     * Emit event to reorder collection for a fieldset
     *
     * @param collectionIds
     */
    switchPosition(collectionIds: number[], field: any): void {
        this.onSwitchCollections.emit({ collectionIds: collectionIds, field: field });
    }

    checkOptionSelected(field, value) {
        let result = false;
        if (field && ((field.id && field.id === value.id) || field == value.id)) {
            result = true;
        }
        return result;
    }

    /**
     * Configure a table field
     * @param field
     * @param collectionId
     * @param inputId
     * @param collectionBisId
     * @param inputBisId
     */
    openConfigureTableModal(field: any, collectionId: number, inputId: number, collectionBisId?: number, inputBisId?: number) {
        this.currentCollectionId = collectionId;
        this.currentInputId = inputId;

        if (field) {
            try {
                if (field.hasOwnProperty("collections") && field.collections.length && collectionId && inputId) {
                    const elementValue = this.findElementValue(field);
                    if (elementValue) {
                        if (elementValue.fieldType === "fieldset") {
                            this.disabledTableImport(collectionBisId, inputBisId);
                            this.currentCollectionBisId = collectionBisId;
                            this.currentInputBisId = inputBisId;

                            this.currentTable = JSON.parse(elementValue.value[collectionBisId][inputBisId].elementValue);
                            this.currentTitle = elementValue.value[collectionBisId][inputBisId].input;
                        } else {
                            this.disabledTableImport(null, null);
                            this.currentTable = JSON.parse(elementValue.value);
                            this.currentTitle = elementValue.input;
                        }
                    }
                } else {
                    this.currentTable = JSON.parse(field.elementValue[0].value);
                    this.currentTitle = field.name;
                }
            } catch (error) {
                // In case we add a field without configure it (it will create en element value with "" as value and break parse)
                this.currentTable = null;
            }

            this.currentFieldId = field.id;
            this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
        }
    }

    /**
     * Import table from element type field default value after it was found
     * @param sectionFields
     * @param source
     * @param collectionBisId
     * @param inputBisId
     * @param sectionName
     */
    importTableFromSource(sectionFields: any, source: string, collectionBisId?: number, inputBisId?: number, sectionName?: string): void {
        if ("model" === source) {
            let oldCurrentTable = this.currentTable;
            let field = this.findField(sectionFields);

            if (field) {
                if (field.hasOwnProperty("collections") && field.collections.length && this.currentCollectionId && this.currentInputId) {
                    field = this.findElementValue(field);
                }

                if (field.fieldType == "fieldset" && collectionBisId !== null && inputBisId !== null) {
                    oldCurrentTable = JSON.stringify(field.value[collectionBisId][inputBisId].elementValue);
                    field.value[collectionBisId][inputBisId].elementValue = field.value[collectionBisId][inputBisId].tableModel ? field.value[collectionBisId][inputBisId].tableModel : "";
                    this.currentTable = JSON.stringify(field.value);
                } else {
                    this.currentTable = field.tableModel ? field.tableModel : "";
                }

                this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
                this.saveTable(sectionFields, oldCurrentTable, this.currentTable, sectionName);
            }
        } else {
            this._tableService.importError(source);
        }
    }

    /**
     * Let to disable import buttons
     * @param sectionFields
     * @returns {boolean}
     */
    controlSource(sectionFields: any): boolean {
        let control;
        let field = this.findField(sectionFields);

        if (field) {
            if (field.hasOwnProperty("collections") && field.collections.length && this.currentCollectionId && this.currentInputId) {
                field = this.findElementValue(field);
            }

            if (field.tableModel) {
                control = this.currentTable !== field.tableModel;
            } else {
                control = false;
            }
        }

        return control;
    }

    /**
     * Find field based on current opened table
     * @param sectionFields
     * @returns
     */
    findField(sectionFields: any) {
        let field;

        if (sectionFields) {
            field = sectionFields.find((field) => field.id === this.currentFieldId);
        }

        return field;
    }

    /**
     * Find elementValue based on collection and input id
     * @param field
     * @returns
     */
    findElementValue(field: any) {
        const collectionField = field.collections.find((collection) => collection.id === this.currentCollectionId);

        let elementValue;
        if (collectionField) {
            elementValue = collectionField.elementValueView.find((input) => input.inputId === this.currentInputId);
        }

        return elementValue;
    }

    changeDisplay(field, collectionId = null, inputId = null, inputCollection = null, inputInput = null) {
        if (Number.isInteger(collectionId) && Number.isInteger(inputId)) {
            if (Number.isInteger(inputCollection) && Number.isInteger(inputInput)) {
                this.displayAll[field.id][collectionId][inputId][inputCollection][inputInput] = !this.displayAll[field.id][collectionId][inputId][inputCollection][inputInput];
            } else {
                this.displayAll[field.id][collectionId][inputId] = !this.displayAll[field.id][collectionId][inputId];
            }
        } else {
            this.displayAll[field.id] = !this.displayAll[field.id];
        }
    }

    displayListProductLink(productLinkSelected: any, allProductLink: any) {
        let result = [];
        productLinkSelected.forEach((selected) => {
            let productLink = allProductLink.find((product) => selected.id == product.id);
            if (productLink) {
                result.push(productLink);
            }
        });

        return result;
    }

    getInputValueRef(collection, inputRef, pos) {
        let result = collection.map((input) => input.inputId).indexOf(inputRef);
        if (collection[result]) {
            if (collection[result].fieldType === "list" && collection[result].value) {
                let indexListValue = collection[result].list.map((val) => val.id).indexOf(Number(collection[result].value));

                return indexListValue >= 0 ? collection[result].list[indexListValue].name.replaceAll(/\<.*?\>/g, "") : pos + 1;
            }

            if (collection[result].fieldType === "textarea" && collection[result].value) {
                return collection[result].value.replaceAll(/\<.*?\>/g, "") || pos + 1;
            }

            if (collection[result].fieldType === "date" && collection[result].value) {
                return this._dataService.formatDateValue(collection[result].value) || pos + 1;
            }
        }
        return collection[result] && collection[result].value ? collection[result].value.replaceAll(/\<.*?\>/g, "") : pos + 1;
    }

    isString(value: any): boolean {
        return typeof value === "string";
    }

    getFieldsetInputValueRef(collection, inputRef, pos) {
        let result = collection.map((input) => input.id).indexOf(inputRef);
        if (collection[result]) {
            if (collection[result].fieldType === "list" && collection[result].elementValue) {
                let indexListValue = collection[result].list.map((val) => val.id).indexOf(Number(collection[result].elementValue));

                return indexListValue >= 0 ? collection[result].list[indexListValue].name.replaceAll(/\<.*?\>/g, "") : pos + 1;
            }
            if (typeof collection[result].elementValue === "object") {
                return collection[result] && collection[result].elementValue ? collection[result].elementValue.replaceAll(/\<.*?\>/g, "")?.name.replaceAll(/\<.*?\>/g, "") : pos + 1;
            }
            if (collection[result].fieldType === "textarea" && collection[result].elementValue) {
                return collection[result].elementValue.replaceAll(/\<.*?\>/g, "") || pos + 1;
            }

            if (collection[result].fieldType === "date" && collection[result].elementValue) {
                return this._dataService.formatDateValue(collection[result].elementValue) || pos + 1;
            }
        }

        return collection[result] && collection[result].elementValue ? collection[result].elementValue.replaceAll(/\<.*?\>/g, "") : pos + 1;
    }

    configProductLinkDisplay(sections, sectionNames) {
        sectionNames.forEach((sectionName) => {
            sections[sectionName].forEach((field) => {
                this.displayAll[field.id] = false;
                if (field.fieldType === "fieldset") {
                    this.displayAll[field.id] = [];
                    field.collections.forEach((col, indexCollection) => {
                        this.displayAll[field.id][indexCollection] = [];
                        col.elementValueView.forEach((input) => {
                            this.displayAll[field.id][indexCollection][input.inputId] = false;
                            if (input.fieldType === "fieldset") {
                                this.displayAll[field.id][indexCollection][input.inputId] = [];
                                if (input.value) {
                                    input.value.forEach((colInput, indexColInput) => {
                                        colInput.forEach((inp) => {
                                            this.displayAll[field.id][indexCollection][input.inputId][indexColInput] = [];
                                            this.displayAll[field.id][indexCollection][input.inputId][indexColInput][inp.id] = false;
                                        });
                                    });
                                }
                            }
                        });
                    });
                }
            });
        });
    }

    addCollectionInput(field, collectionId, input, sectionName, dataSource = null) {
        let newCol = [];
        this.underline = !this.underline;
        const sourceIndex = dataSource ? dataSource.collections.length : 1;

        for (let i = 0; i < sourceIndex; i++) {
            const sourceCollection = dataSource ? dataSource.collections[i] : null;
            let newColInputs = [];

            for (let j = 0; j < input.inputs.length; j++) {
                let newInput = { ...input.inputs[j] };

                newInput["list"] = newInput["listValues"];
                newInput["defaultValue"] = null;

                let baseValue = newInput["fieldType"] == "list" ? [] : "";

                if (sourceCollection) {
                    // Duplication case
                    const sourceInput = sourceCollection.elementValueView.find((input) => {
                        return input.inputId === newInput.inputSourceId;
                    });

                    if (sourceInput) {
                        baseValue = sourceInput.value;
                    }
                }

                newInput["elementValue"] = baseValue;
                newInput["inputs"] = [];

                newColInputs.push(newInput);
            }

            newCol.push(newColInputs);
        }

        let indexField = this.sections[sectionName].map((fieldSection) => fieldSection.id).indexOf(field.id);
        let indexCollection = this.sections[sectionName][indexField].collections.map((collectionSection) => collectionSection.id).indexOf(collectionId);
        let indexInput = this.sections[sectionName][indexField].collections[indexCollection].elementValueView.map((inputSection) => inputSection.id).indexOf(input.id);

        newCol.forEach((newCol) => {
            let fullCol =
                this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value !== null
                    ? this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value
                    : [];

            this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value = [];
            fullCol.push(newCol);

            fullCol.forEach((col) => {
                this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value.push(col);
            });
        });

        let result = JSON.stringify(this.sections[sectionName][indexField].collections[indexCollection].elementValueView[indexInput].value);
        let allproductLink = newCol.filter((input) => input.fieldType == "productLink");

        this.saveCollectionField(field, collectionId, input.id, input.inputId, result, indexField, indexCollection, indexInput);
        let indexNewCol = this.displayAll[field.id][indexCollection][input.inputId].length;
        this.displayAll[field.id][indexCollection][input.inputId][indexNewCol] = [];
        allproductLink.forEach((inputProdLink) => {
            this.displayAll[field.id][indexCollection][input.inputId][indexNewCol][inputProdLink.id] = false;
        });

        // nav.select(nav.items._results.length);
    }

    saveValueInput(value, field, collectionId, inputId, input, collections, sectionName = null) {
        // let indexField = this.sections[sectionName].map((fieldSection) => fieldSection.id).indexOf(field.id);
        collections[collectionId][inputId].elementValue = value;
        let result = JSON.stringify(collections);
        this.saveCollectionField(field, collectionId, input.id, input.inputId, result);
    }

    /**
     *
     * @param indexCollection
     * @param collectionId
     * @param field
     * @param input
     * @param collections
     */
    removeCollectionInput(indexCollection: number, collectionId, field, input, collections): void {
        this.underline = !this.underline;

        this._modal.delete().then((result) => {
            if (result.value) {
                collections.splice(indexCollection, 1);

                let result = JSON.stringify(collections);

                this.saveCollectionField(field, collectionId, input.id, input.inputId, result);
            }
        });
    }

    disabledTableImport(collectionBisId, inputBisId) {
        this.importTableDisabled = collectionBisId !== null || inputBisId !== null;
    }

    controlFieldsetSource(event: MouseEvent, field: any, nav, collectionId, input, sectionName) {
        this.fieldsetSources = {};
        this.contextFieldset = field;
        let fieldsetSourceValue;

        if (collectionId && input && sectionName !== undefined) {
            this.isCollectionInput = true;
            this.contextSubdata = { collectionId: collectionId, input: input, sectionName: sectionName };
            fieldsetSourceValue = input.inputs && input.inputs.length ? input.inputs[0].fieldsetSource : null;
        } else {
            this.isCollectionInput = false;
            this.contextSubdata = null;
            fieldsetSourceValue = field.inputs.fieldsetSource;
        }

        // WARNING : At this level we only dispose of tab's data (sections)
        if (
            (!this.isCollectionInput && !field.collections.length && fieldsetSourceValue) ||
            (this.isCollectionInput && /*input.value &&*/ (null === input.value || !input.value.length) && fieldsetSourceValue)
        ) {
            let sources = false;

            Object.entries(this.sections).forEach((section) => {
                this.fieldsetSources[section[0]] = [];

                section[1].forEach((sectionField) => {
                    if ("fieldset" === sectionField.fieldType && sectionField.collections.length && sectionField.inputs.fieldset === fieldsetSourceValue) {
                        this.fieldsetSources[section[0]].push(sectionField);
                        sources = true;
                    }
                });
            });

            if (false === sources) {
                if (this.isCollectionInput) {
                    this.addCollectionInput(field, collectionId, input, sectionName);
                } else {
                    this.addCollection(field);
                }
            } else {
                this.displaySourceSelection = sources;
            }
        } else {
            if (this.isCollectionInput) {
                this.addCollectionInput(field, collectionId, input, sectionName);
            } else {
                this.addCollection(field);
            }
        }

        if (this.isCollectionInput) {
            nav.select(nav.items._results.length);
        } else {
            setTimeout(() => nav.select(nav.items._results.length));

            event.preventDefault();
        }
    }

    processSource() {
        if ("null" !== this.selectedSource) {
            if (this.contextSubdata) {
                // Duplicate sub fieldset case
                this.addCollectionInput(this.contextFieldset, this.contextSubdata.collectionId, this.contextSubdata.input, this.contextSubdata.sectionName, this.selectedSource);
            } else {
                // Duplicate fieldset case
                this.onAddCollection.emit({
                    field: this.contextFieldset,
                    payload: this.selectedSource,
                    duplicateFieldsetData: true,
                });
            }
        } else {
            if (this.isCollectionInput) {
                this.addCollectionInput(this.contextFieldset, this.contextSubdata.collectionId, this.contextSubdata.input, this.contextSubdata.sectionName);
            } else {
                this.addCollection(this.contextFieldset);
            }
        }
    }

    displayCollection(field: any, collection = null, input = null, collectionFieldset = null, inputCollection = null): boolean {
        let result = false;
        if (!this.displaySortableStates[field]) {
            result = true;
        } else {
            if (!this.displaySortableStates[field][collection]) {
                result = true;
            } else {
                if (!this.displaySortableStates[field][collection][input]) {
                    result = true;
                } else {
                    if (
                        collectionFieldset >= 0 &&
                        inputCollection >= 0 &&
                        this.displaySortableStates[field][collection][input][collectionFieldset] &&
                        !this.displaySortableStates[field][collection][input][collectionFieldset][inputCollection]
                    ) {
                        result = true;
                    }
                }
            }
        }

        return result;
    }
}
