<div id="flatplan-view" class="d-none d-md-block"></div>

<app-flatplan class="d-none d-md-block" [pages]="flatplan" [covers]="covers" (movePage)="onPageMove($event)"
    (pageAction)="onPageAction($event)" (reInitCover)="createCovers()"
    *ngIf="views[0] && viewType === views[0].type && 'thumbnail' === views[0].options">
</app-flatplan>

<app-pages-list *ngIf="!views.length || views[1] && viewType === views[1].type && 'list' === views[1].options" [pages]="flatplan" [activePanel]="globalMode" (movePage)="onPageMove($event)"
    (pageAction)="onPageAction($event)" [versionName]="this.versionName" [isFlatplan]="isExportFlatplan">
</app-pages-list>

<swal #newPage title="{{ (isExportFlatplan ? 'projects.flatplan.new' : 'projects.flatplan.newExp') | translate }}" confirmButtonText="{{ 'general.create' | translate }}"
    (close)="isOpen = false" (cancel)="isOpen = false" [preConfirm]="preConfirm()" (confirm)="sendForm(form.value)" [showCancelButton]="true"
    cancelButtonText="{{ 'general.cancel' | translate }}" [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="form">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control"
                        (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control"
                        (change)="setMaxElements($event.target.value);" (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.template' | translate }}
                </label>
                <div class="col-8">                    
                    <p-multiSelect formControlName="template" [options]="templateList" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'breadcrumb.project.flatplan.noTemplate' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #updatePage title="{{ 'projects.flatplan.configurePage' | translate }}"
    confirmButtonText="{{ 'general.update' | translate }}" (close)="isOpen = false" (cancel)="isOpen = false" [preConfirm]="preConfirm()"
    (confirm)="warningUpdate(false)" [showCancelButton]="true" cancelButtonText="{{ 'general.cancel' | translate }}"
    [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="form">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row"  *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control"
                        (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row"  *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control"
                        (change)="setMaxElements($event.target.value);" (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row" >
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.template' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="template" [options]="templateList" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'breadcrumb.project.flatplan.noTemplate' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #updatePageDuplicate title="{{ 'projects.flatplan.configurePage' | translate }}"
    confirmButtonText="{{ 'general.update' | translate }}" (close)="isOpenDuplicate = false" (cancel)="isOpenDuplicate = false" [preConfirm]="preConfirm()"
    (confirm)="warningUpdate(true)" [showCancelButton]="true" cancelButtonText="{{ 'general.cancel' | translate }}"
    [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="formUpdateDuplicate">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control"/>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control"/>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #newPageDuplicate title="{{ 'projects.flatplan.newDuplicate' | translate }}" confirmButtonText="{{ 'general.create' | translate }}"
    (close)="isOpenDuplicate = false" (cancel)="isOpenDuplicate = false" [preConfirm]="preConfirmDuplicate()" (confirm)="sendForm(formDuplicate.value)" [showCancelButton]="true" [showConfirmButton]="formDuplicate.valid"
    cancelButtonText="{{ 'general.cancel' | translate }}" [cancelButtonColor]="'#FF0000'">
    <form *swalPortal [formGroup]="formDuplicate">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.name' | translate }}
                </label>
                <div class="col-8">
                    <input type="text" formControlName="name" class="form-control"/>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left"
                    [ngClass]="{ 'text-danger': formDuplicate.controls['pages'].touched && formDuplicate.controls['pages'].invalid }">
                    {{ 'general.referringPage' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="pages" [options]="pagesMaster" optionLabel="name" [selectionLimit]="1" defaultLabel="{{ 'general.select' | translate }}" [style]="{'width':'100%'}" (onChange)="updatePageProductLength()"></p-multiSelect>
                    <small class="text-danger form-text first-letter"
                    *ngIf="formDuplicate.controls['pages'].touched && formDuplicate.controls['pages'].hasError('required')">
                    {{ 'general.required' | translate }}
                </small>
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="pageLength" class="form-control"
                        (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row" *ngIf="!isExportBooklet">
                <label class="col-4 col-form-label text-left">
                    {{ 'projects.flatplan.length.element' | translate }}
                </label>
                <div class="col-8">
                    <input type="number" min="0" formControlName="elementsLength" class="form-control"
                        (change)="setMaxElements($event.target.value);" (debounceInput)="setTemplateList()" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-4 col-form-label text-left">
                    {{ 'general.products' | translate }}
                </label>
                <div class="col-8">
                    <p-multiSelect formControlName="elementList" [options]="elements" optionLabel="name" [selectionLimit]="selectElementLimit" defaultLabel="{{ 'breadcrumb.project.flatplan.noElement' | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>
        </div>
    </form>
</swal>

<swal #newFlatplan title="{{ (isExportHtml ? 'projects.html.new' : 'projects.flatplan.create') | translate }}"
    confirmButtonText="{{ 'general.create' | translate }}" (cancel)="redirectToHome()" (close)="isOpenFlatplan = false"
    [preConfirm]="preConfirmFlatplan()" (confirm)="sendFormFlatplan(formFlatplan.value)">
    <form *swalPortal [formGroup]="formFlatplan">
        <div class="modal-body">
            <div class="form-group row">
                <label class="col-7 col-form-label text-left"
                    [ngClass]="{ 'text-danger': submittedFlatplan && formFlatplan.controls['pageLength'].invalid }">
                    {{ 'projects.flatplan.length.page' | translate }}
                </label>
                <div class="col-5">
                    <input type="number" min="0" formControlName="pageLength" class="form-control" />
                </div>
            </div>
        </div>
    </form>
</swal>

<swal [customClass]="'col-10'" #preview title="{{ 'projects.flatplan.preview' | translate }}"
    [showConfirmButton]="false" [showCancelButton]="false" [showCloseButton]="true">
    <div *swalPortal>
        <embed *ngIf="!isExportHtml" style="width: 100%; height: 85vh;" [src]="previewUrl" type="application/pdf">
        <iframe *ngIf="isExportHtml" style="width: 100%; height: 85vh;" [src]="previewUrl"></iframe>
    </div>
</swal>

<swal #importPDF title="{{'projects.flatplan.importPDF' | translate}}" [preConfirm]="preConfirmImportPDF()" (close)="OnCloseImportPdfModal()" [showCloseButton]="true" confirmButtonText="{{ 'general.create' | translate }}">
    <div *swalPortal>
        <input type="file" id="file-to-upload" accept="application/pdf" (change)="showPDF($event)"/>
        <embed *ngIf="selectPDF != ''" style="width: 100%; height: 85vh;" [src]="selectPDF"  type="application/pdf">
        <label *ngIf="selectPDF != '' && !savePdf" style="color: red;"> {{'projects.flatplan.onePagePdf' | translate}} </label>
    </div>

</swal> 
