<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header d-block">
        <h4 class="modal-title text-truncate mb-2" id="modal-basic-title">{{ media.name }}</h4>
        <h6 *ngIf="media.dateCreate" class="font-weight-light text-primary text-sm font-italic">{{ "general.date.create"
            | translate }} : {{ media.dateCreate }}</h6>
        <h6 *ngIf="media.dateUpdate" class="font-weight-light text-primary text-sm font-italic">{{ "general.date.update"
            | translate }} : {{ media.dateUpdate }}</h6>
    </div>
    <div class="modal-body">
        <div class="row" style="height: 100%;">
            <div class="col-6 justify-content-center">
                <div id="image-container" class="border border-secondary justify-content-center text-center mx-auto"
                    style="height: 40%; max-height: 40%">

                    <!-- If media are images files -->
                    <img data-enlargeable id="image" *ngIf="previewData && previewData.metadata.mimeType.indexOf('image/') > -1" [ngStyle]="getImageStyle()"
                        class="mx-auto fade-in" style="cursor: zoom-in;"
                        [src]="previewData.filePath ? damUrl+'/'+previewData.filePath : getThumb({ binImage: previewData.file })" alt="..." />
                    <!-- end -->

                    <!-- If media are videos files -->
                    <video data-enlargeable id="image" controls width="auto" height="240" *ngIf="previewData && previewData.metadata.mimeType.indexOf('video/') > -1" [ngStyle]="getImageStyle()"
                           class="mx-auto fade-in" >

                        <source [src]="previewData.filePath ? damUrl+'/'+previewData.filePath : getThumb({ binImage: previewData.file })"
                                [type]="previewData.metadata.mimeType">
                    </video>
                    <!-- end -->

                    <!-- If media are audios files -->
                    <audio data-enlargeable id="image" *ngIf="previewData && previewData.metadata.mimeType.indexOf('audio/') > -1" controls [src]="previewData.filePath ? damUrl+'/'+previewData.filePath : getThumb({ binImage: previewData.file })"></audio>
                    <!-- end -->

                    <!-- If media are others files -->
                    <a [href]="damUrl+'/'+previewData.filePath" target="_blank">
                        <img data-enlargeable id="image" *ngIf="previewData && !(previewData.metadata.mimeType.indexOf('image/') > -1) && !(previewData.metadata.mimeType.indexOf('video/') > -1) && !(previewData.metadata.mimeType.indexOf('audio/') > -1)" [ngStyle]="getImageStyle()" class="mx-auto fade-in" style="cursor: zoom-in;" [src]="previewData.thumbPath ? damUrl+'/'+previewData.thumbPath : damUrl+'/default/default.png'" alt="..." />
                    </a>
                    <!-- end -->
                </div>
                <div *ngIf="media.tags" class="mt-2 ml-2" style="height: 60%; max-height: 60%;">
                    <span *ngFor="let tag of media.tags" class="badge badge-primary badge-pill mr-1">
                        <span class="pointer mr-1">{{ tag.name }}</span>
                        <span *ngIf="canAddTag" class="pointer" (click)="removeTag(media, tag.id)">x</span>
                    </span>
                    <span *ngIf="canAddTag" (click)="openTagModal(media);"
                        class="badge badge-pill badge-primary mr-1 pointer">+</span>

                    <div *ngIf="previewData.children.length > 0" class="slider-container mt-4">

                        <div class="mySlides" *ngFor="let child of previewData.children">
                            <img class="pointer" (click)="media = child; update()" [src]="child.thumbPath ? damUrl+child.thumbPath : getChildThumb(child.thumb)"
                                [alt]="child.name">
                        </div>

                        <a class="prev" (click)="nextSlide(-1)">&#10094;</a>
                        <a class="next" (click)="nextSlide(1)">&#10095;</a>

                        <div class="caption-container">
                            <p id="caption"></p>
                        </div>
                        
                        <div class="row-slider " style="opacity: 0.8;" style="overflow-x:scroll; overflow-y: auto; width: 100%;">
                            <ul class="list-group list-group-horizontal" style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; max-width: 100%;">
                                <li style="padding-right: 5px; list-style-type: none" *ngFor="let child of previewData.children; let i = index">
                                    <img class="demo pointer" [src]="child.thumbPath ? damUrl+child.thumbPath : getChildThumb(child.thumb)" [alt]="child.name"
                                    style="height: 4rem;" (click)="setCurrentSlide(i + 1)">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-6 mt-1 p-0 edit-form">
                <ng-container *ngIf="previewData">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active">
                        <li [ngbNavItem]="1" *ngIf="previewData.metadata.metadata">
                            <a class="text-primary" ngbNavLink>{{ "dam.metadata.title" | translate }}</a>
                            <ng-template ngbNavContent>
                                <div class="mt-1" [innerHTML]="metadata"></div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2" >
                            <a class="text-primary" ngbNavLink>{{ "dam.metadata.maestro" | translate }}</a>
                            <ng-template ngbNavContent>
                                <ng-container *ngIf="acl.MAESTRO_DAM_MEDIA_UPDATE; else elseBlock" ngProjectAs="[edit-form]">
                                    <app-edit-form [preview]="true" [mediaId]="media.id"></app-edit-form>
                                </ng-container>

                                <ng-template #elseBlock>
                                    <div class="mt-1" [innerHTML]="fields"></div>
                                </ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3" *ngIf="previewData.story && previewData.story.length > 0">
                            <a class="text-primary" ngbNavLink>{{ "history.title" | translate }}</a>
                            <ng-template ngbNavContent>
                                <table class="table table-striped">
                                    <tbody>
                                        <tr *ngFor="let item of previewData.story">
                                            <td>{{ item.type }}</td>
                                            <td>{{ item.date }}</td>
                                            <td>{{ item.username }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-template>
                        </li>
                    </ul>
                    <div style="height: 90%;" [ngbNavOutlet]="nav"></div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="p-3 border-top border-black">
        <button type="button" class="btn btn-primary" (click)="previousMedia()">{{ 'general.previous' | translate
            }}</button>
        <button type="button" class="btn btn-primary ml-1" (click)="nextMedia()">{{ 'general.next' | translate
            }}</button>
        <button *ngIf="previewData.origin" type="button" class="btn btn-primary ml-1"
            (click)="media = previewData.origin; update()">{{ "dam.origin" | translate }}</button>
        <div class="float-right btn-group">
            <button class="btn btn-primary" (click)="download(previewData.fileHD ? damUrl+previewData.fileHD : '')">
                <fa-icon [icon]="faDownload"></fa-icon>
            </button>
            <button
                *ngIf="buttons.crop && !previewData.origin && (media.extension === 'jpg' || media.extension === 'png' || media.extension === 'jpeg')"
                class="btn  btn-primary" (click)="cropMedia(media.id)">
                <fa-icon [icon]="faCrop"></fa-icon>
            </button>
            <button *ngIf="buttons.cart" class="btn btn-primary" (click)="addCart.emit(media)">
                <fa-icon [icon]="faBucket"></fa-icon>
            </button>
            <button *ngIf="buttons.fill && media.model" class="btn btn-primary" (click)="editMedia(media.id)">
                <fa-icon [icon]="faEdit"></fa-icon>
            </button>
            <button *ngIf="buttons.delete" class="btn btn-danger" (click)="deleteMedia(media.id)">
                <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
            <button *ngIf="buttons.unlink" class="btn btn-danger" (click)="unlinkMedia(media.id)">
                <fa-icon [icon]="faUnlink"></fa-icon>
            </button>
        </div>
    </div>
</ng-template>

<app-cropper #cropper [defaultRatio]="true" (cropped)="croppedMedia($event)"></app-cropper>

<swal #datamodel title="{{ 'modal.datamodel' | translate }}" confirmButtonText="{{ 'modal.confirm' | translate }}">
    <div *swalPortal>
        <span *ngIf="media.model" class="text-danger font-weight-bold">{{ "modal.mediadam.updateDm" | translate
            }}</span>
        <form class="mt-2" [formGroup]="dataModelForm">
            <app-drop-down [type]="'single'" [list]="dataModels" formControlName="dm"></app-drop-down>
        </form>
    </div>
</swal>

<swal #addTagModal title="TBD" confirmButtonText="{{ 'modal.add' | translate }}">
    <div *swalPortal>
        <form [formGroup]="formAddTag">
            <app-drop-down [type]="'multi'" [list]="filteredTags" formControlName="tag"></app-drop-down>
        </form>
    </div>
</swal>
