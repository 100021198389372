import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import * as JsZip from "jszip";
import { Observable } from "rxjs";
import { environment } from "src/environments";
import { LayoutService } from "../../global/layout/layout.service";
import { ToastService } from "../../global/toast/toast.service";

@Injectable()
export class PageService {
    constructor(private _http: HttpClient, private _layout: LayoutService, private _toastService: ToastService, private _translateService: TranslateService) {}

    /**
     * Create Flatplan
     * @param idExport
     * @param nbPagesCdf
     * @returns
     */
    createCdf(idExport: number, nbPagesCdf: number): Observable<any> {
        const value = {
            nbProducts: null,
            nbPages: nbPagesCdf,
        };
        return this._http.put(`${environment.projectsUrl}/export/${idExport}/cdf.json`, value);
    }

    /**
     * Create a new page
     *
     * @param idCdf
     * @param value
     * @returns
     */
    createPage(idCdf: number, value: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/cdf/${idCdf}/page.json`, value);
    }

    /**
     * Create a new page
     *
     * @param idCdf
     * @param value
     * @returns
     */
    createPageDuplicate(idPage: number, value: any): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/${idPage}/duplicate.json`, value);
    }

    /**
     * Update an existing page
     *
     * @param idCdf
     * @param idPage
     * @param value
     * @returns
     */
    updatePage(idCdf: number, idPage: string, value: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/cdf/${idCdf}/page/${idPage}.json`, value);
    }

    /**
     * Update an existing page
     *
     * @param idDuplicate
     * @param elements
     * @param name
     * @returns
     */
    updatePageDuplicate(idDuplicate: number, value: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/page/${idDuplicate}/duplicate.json`, value);
    }

    getIdCdf(idExport: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/get_cdf/${idExport}.json`);
    }

    /**
     * Save template
     * @param id
     * @param template
     * @returns
     */
    saveTemplate(id: number, template: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/page/${id}/template.json`, template);
    }

    updateMaxPage(id: Number, nbMaxPage: Number): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/cdf/${id}/pages.json`, { nbPages: nbMaxPage });
    }

    /**
     * Download an archive which contains page generation files
     * @param data
     */
    downloadArchive(data: any): void {
        this._http.post(`${environment.projectsUrl}/page/option/download.json`, data).subscribe((res: any) => {
            this._layout.spinner.show();
            const zip = new JsZip();
            let filesAdded = 0;

            getNestedZip(res.data.files);

            if (filesAdded === res.data.totalFiles) {
                this._toastService.show({ message: this._translateService.instant("projects.export.pending_download"), type: "info" });

                zip.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, `${res.data.zipName}.zip`);
                    this._layout.spinner.hide();
                });
            }

            function getNestedZip(file: any, tree: string = "") {
                for (const [key, val] of Object.entries(file)) {
                    if (!Array.isArray(val)) {
                        getNestedZip(val, tree + "/" + key);
                    } else {
                        val.forEach((element) => {
                            zip.folder(tree + "/" + element.pageOptionName).file(element.originalName, element.file, { base64: true });
                            filesAdded++;
                        });
                    }
                }
            }
        });
    }
}
