import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { faHandPointer, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCrop, faDownload, faEdit, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ElementService } from "app/core/services/pim/element.service";
import { ExportPageService } from "app/core/services/project/export/export-page-view.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { UploadService } from "app/core/services/upload/upload.service";
import { CropperComponent } from "app/shared/components/cropper/cropper.component";
import { ThumbButtons } from "app/shared/helpers/thumb-buttons";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { ExportElementMedia } from "app/shared/models/project/export-element.model";
import { Thumb } from "app/shared/models/thumb";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { LazyLoadEvent, SelectItem } from "primeng-lts/api";
import { Subject, Subscription } from "rxjs";
import { environment } from "src/environments";
import { activatedModules } from "src/environments/activated-modules";
import Swal from "sweetalert2";

@Component({
    selector: "app-page-image-tab",
    templateUrl: "./page-image-tab.component.html",
    styleUrls: ["../../export-page-view.component.scss"],
})
export class PageImageTabComponent implements OnInit {
    medias = [];
    @Input() places: ExportElementMedia[] = [];
    @Input() product: any;

    @Output() select = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();
    @Output() field = new EventEmitter<any>();

    @Input() disabled: boolean;

    form: FormGroup;
    selectedImage: any;

    currentCrop: any;

    uploadImage: any;

    ImageBaseData: string | ArrayBuffer = null;
    activatedModules = activatedModules;
    state: string = "all";

    public buttons = ThumbButtons.asPageButtons();

    public filterOptions: { id: number; name: string; type: string; types?: string[] }[] = [];
    private _mediaTypes: any[];
    private subscriptions: Subscription[] = [];
    readonly faSearch = faSearch;
    readonly faCrop = faCrop;
    readonly faDownload = faDownload;
    readonly faTrashAlt = faTrashAlt;
    readonly faHandPointer = faHandPointer;
    readonly faPlus = faPlus;
    readonly faEdit = faEdit;

    arraysProducts: any = [];

    @ViewChild("mediaSwal") mediaSwal: SwalComponent;
    @ViewChild("cropper") cropper: CropperComponent;
    @ViewChild("importSwal") importSwal: SwalComponent;
    @ViewChild("fieldSwal") fieldSwal: SwalComponent;

    currentPlace: any;
    globalFilter: string = "";

    currentDatatableFilters: LazyLoadEvent = {
        first: 0,
        globalFilter: this.globalFilter,
        rows: 12,
    }; // Use to reload data when filter with tag/media type & action like delete

    filters: any = {
        tags: [],
        type: [], // Contains extension
        mediaIds: [],
    };

    sortOptions: SelectItem[] = [];
    recordsTotal: number = 0;
    recordsFiltered: number = 0;

    eventsSubject: Subject<Thumb[]> = new Subject<Thumb[]>();
    damUrl = environment.damUrl;

    constructor(
        private formBuilder: FormBuilder,
        private elementService: ElementService,
        private _translate: TranslateService,
        public readonly swalTargets: SwalPortalTargets,
        private _modalService: SwalModalService,
        private _sanitizer: DomSanitizer,
        private _service: ExportPageService,
        private _uploadService: UploadService,
        private _thumbnailService: ThumbnailsService
    ) {}

    /**
     * Open crop [WIP]
     *
     * @param place
     * @param media
     */
    openCrop(place: ExportElementMedia, media?): void {
        this.currentPlace = place;
        this.currentCrop = media ? media : this.medias.find((m) => m.id === place.mediaId);

        this.cropper.openCrop(
            place.mediaId,
            place.positionPhotoWidth,
            place.positionPhotoHeight,
            {
                flipH: place.flipHorizontal,
                flipV: place.flipVertical,
                rotate: place.rotate,
                scale: 1,
            },
            this.currentPlace
        );
    }

    /**
     * Save the crop
     *
     * @param data
     */
    cropMedia(data: { croppedImage: ImageCroppedEvent; transform: ImageTransform; rotation: number }): void {
        this.currentPlace.height = data.croppedImage.height;
        this.currentPlace.width = data.croppedImage.width;
        this.currentPlace.x = data.croppedImage.cropperPosition.x1;
        this.currentPlace.y = data.croppedImage.cropperPosition.y1;
        this.currentPlace.flipHorizontal = data.transform.flipH || false;
        this.currentPlace.flipVertical = data.transform.flipV || false;

        this.currentPlace.rotate = data.rotation;

        this.select.emit([this.currentCrop, this.currentPlace, true]);
        this.selectedImage = this.currentCrop;
        this.places.forEach((p) => {
            if (p.position === this.currentPlace.position) {
                p.mediaId = this.currentPlace.mediaId;
            }
        });
    }

    ngOnInit(): void {
        this._uploadService.getMediaTypes().subscribe((data) => {
            this._mediaTypes = data;
        });

        const productData = Object.create(this.places);

        for (const obj of productData) {
            this.arraysProducts.push(obj);
        }
    }

    /**
     * Open select media modal
     * @param place
     */
    openModal(place: any): void {
        this.mediaSwal.fire();
        this.currentPlace = place;
    }

    /**
     * Unlink media from a position
     *
     * @param place
     */
    unselected(place: ExportElementMedia): void {
        Swal.fire({
            title: this._translate.instant("dam.media.delete"),
            showCancelButton: true,
            confirmButtonText: this._translate.instant("general.remove"),
            cancelButtonText: this._translate.instant("general.cancel"),
            cancelButtonColor: "#FF0000",
        }).then((result) => {
            if (result.isConfirmed) {
                this.selectedImage = null;
                this.delete.emit(place.exportMediaId);
                this.resetPlace(place);
            }
        });
    }

    /**
     * Link a media to a position
     * @param media
     */
    selectMedia(media: any): void {
        let html = "";
        if (media.copyright || this.currentPlace.copyright) {
            let copyright = this._translate.instant("general.noData");
            if (media.copyright) {
                copyright = media.copyright;
            }
            html = html + "<div> " + this._translate.instant("dam.metadata.copyright") + ": " + copyright + " <i class='fas fa-arrow-right'></i> " + this.currentPlace.copyright + "</div>";
        }
        if (media.legend || this.currentPlace.legend) {
            let legend = this._translate.instant("general.noData");
            if (media.legend) {
                legend = media.legend;
            }
            html = html + "<div> " + this._translate.instant("dam.legend") + ": " + legend + " <i class='fas fa-arrow-right'></i> " + this.currentPlace.legend + "</div>";
        }
        if (media.dateValid || this.currentPlace.dateValid) {
            let dateValid = this._translate.instant("general.noData");
            if (media.dateValid) {
                dateValid = media.dateValid;
            }
            html = html + "<div> " + this._translate.instant("dam.dateValid") + ": " + dateValid + " <i class='fas fa-arrow-right'></i> " + this.currentPlace.dateValid + "</div>";
        }
        if (this.currentPlace.copyright || this.currentPlace.legend || this.currentPlace.dateValid) {
            Swal.fire({
                title: this._translate.instant("dam.media.updateDateField"),
                html: html,
                showCancelButton: true,
                confirmButtonText: this._translate.instant("modal.yes"),
                cancelButtonText: this._translate.instant("modal.no"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.currentPlace.copyright = media.copyright ? media.copyright : "";
                    this.currentPlace.legend = media.legend ? media.legend : "";
                    this.currentPlace.dateValid = media.dateValid ? media.dateValid : "";

                    this.updateDatePlaceView(this.currentPlace.exportMediaId, this.currentPlace);
                }
                this.select.emit([media, this.currentPlace, false]);
                this.places.forEach((p) => {
                    if (p.position === this.currentPlace.position) {
                        p.mediaId = media.id;
                    }
                });
            });
        } else {
            this.currentPlace.copyright = media.copyright ? media.copyright : "";
            this.currentPlace.legend = media.legend ? media.legend : "";
            this.currentPlace.dateValid = media.dateValid ? media.dateValid : "";
            this.updateDatePlaceView(this.currentPlace.exportMediaId, this.currentPlace);
            this.select.emit([media, this.currentPlace, false]);
            this.places.forEach((p) => {
                if (p.position === this.currentPlace.position) {
                    p.mediaId = media.id;
                }
            });
            Swal.close();
        }
    }

    getPath(path: string): string {
        return `${environment.damUrl}/${path}`;
    }

    /**
     * Get the image for a position
     * @param place
     * @returns
     */
    getMediaImg(place: any): string {
        if (place.thumbPath) {
            return environment.damUrl + place.thumbPath;
        } else if (place.thumb) {
            if (place.thumb.search("thumbnail_maestro") > -1) {
                return environment.damUrl + place.thumb;
            } else {
                return "data:image/png;base64," + place.thumb;
            }
        } else {
            return environment.damUrl + "/default/default.png";
        }
    }

    openImportModal(place: any): void {
        this.currentPlace = place;
        this.importSwal.fire().then((result) => {
            if (result.isConfirmed) {
                if (this.uploadImage == null) {
                    alert("Please select file");
                } else {
                    this._uploadService.uploadMedias(this.uploadImage, false, false).subscribe((data) => {
                        const importedMedia = data.data[0];
                        this.medias.push(importedMedia);

                        this.elementService.getMedias(this.product.id).subscribe((data: any) => {
                            const elementMedias = data.data;

                            elementMedias.push(importedMedia);

                            const mediasData = this.elementService.manageMedia(true, elementMedias);

                            this.elementService.saveMedias(mediasData, this.product.id).subscribe((_) => {
                                this.selectMedia(importedMedia);
                            });
                        });
                    });
                }
            }
        });
    }

    controlPosPhoto() {
        this.places.forEach((posPhoto) => {
            if (posPhoto.mediaId !== -1) {
                let controlMedia = this.medias.find((m) => m.id === posPhoto.mediaId);
                if (controlMedia === undefined) {
                    this.unselected(posPhoto);
                }
            }
        });
    }

    resetPlace(place: ExportElementMedia) {
        place.mediaId = -1;
        place.width = 0;
        place.height = 0;
        place.x = 0;
        place.y = 0;
        place.rotate = 0;
        place.flipHorizontal = false;
        place.flipVertical = false;
    }

    handleFileInput(files: FileList) {
        this.uploadImage = files;
    }

    getBase64SelectImage(files: FileList) {
        let me = this;
        let reader = new FileReader();
        let file = files[0];
        reader.readAsDataURL(file);
        reader.onload = function () {
            me.ImageBaseData = reader.result;
        };

        return this.ImageBaseData;
    }

    changeMedia(place: ExportElementMedia) {
        this.openModal(place);
    }

    openFieldModal(place: ExportElementMedia) {
        this.form = this.formBuilder.group({
            id: [place.exportMediaId ? place.exportMediaId : -1],
            copyright: [place.copyright ? place.copyright : ""],
            legend: [place.legend ? place.legend : ""],
            dateValid: [place.dateValid ? place.dateValid : ""],
        });
        this.fieldSwal.fire().then((result) => {
            if (result.isConfirmed) {
                this.updateFieldMedia(this.form.value);
            }
        });
    }

    /**
     * Update a position media
     * @param data
     */
    updateFieldMedia(data: any): void {
        const pageId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        this._service.updateFieldMedia(data.id, { copyright: data.copyright, legend: data.legend, dateValid: data.dateValid, pageId: pageId }).subscribe((result) => {
            this.updateDatePlaceView(data.id, data);
            this._modalService.success(this._translate.instant("general.deleted"));
        });
    }

    updateDatePlaceView(id: number, data: any) {
        let pos = this.places
            .map(function (p) {
                return p.exportMediaId;
            })
            .indexOf(id);

        this.places[pos].copyright = data.copyright;
        this.places[pos].legend = data.legend;
        this.places[pos].dateValid = data.dateValid;
    }

    getMedias(event: LazyLoadEvent) {
        const customFilters = this.getCustomFilters();
        event.globalFilter = this.globalFilter; // Need to be forced since dataview will not bind it himself

        if (this.product.id) {
            this.elementService./*getMediasId*/ getMedias(this.product.id).subscribe((data: any) => {
                // Filter media type image only
                const mediaImages = data.data.filter((m) => m.type === "image");

                // LAST EDITED
                customFilters.mediaIds = mediaImages.filter((d) => d.id !== null).map((d) => d.id);

                // const requestFilters: LazyLoadDataModel = { lazyLoadFilters: event, customFilters: customFilters, state: this.state }; // LAST EDITED
                // this.getDataviewMedia(requestFilters, event); // LAST EDITED
                this.medias = mediaImages;
            });
        } else {
            const requestFilters: LazyLoadDataModel = { lazyLoadFilters: event, customFilters: customFilters, state: this.state };
            this.getDataviewMedia(requestFilters, event);
        }
    }

    getDataviewMedia(requestFilters, filter) {
        this._thumbnailService.getDataviewMedias(JSON.stringify(requestFilters)).subscribe((resp) => {
            const data = resp.data;
            this.medias = data.medias;

            let translatedOptions = [];
            this.sortOptions = data.sortOptions.forEach((option) => {
                option.label = this._translate.instant("dataview.sortField." + option.label) + " " + this._translate.instant("dataview.sortParameter." + option.translationKey);
                translatedOptions.push(option);
            });

            this.sortOptions = translatedOptions;
            this.recordsTotal = data.recordsTotal;
            this.recordsFiltered = data.recordsFiltered;
            this.currentDatatableFilters = filter;
        });
    }

    openCropMedia(event) {
        this.openCrop(this.currentPlace, event);
    }

    removeCrop() {
        this.select.emit([this.currentCrop, this.currentPlace, false]);
        this.places.forEach((p) => {
            if (p.position === this.currentPlace.position) {
                p.mediaId = this.currentPlace.mediaId;
            }
        });
        this.currentPlace.height = 0;
        this.currentPlace.width = 0;
        Swal.close();
    }

    loadListWithSearch(value: string) {
        this.globalFilter = value;

        if ("" !== value) {
            this.currentDatatableFilters.first = 0;
        }

        this.getMedias(this.currentDatatableFilters);
    }

    resetSelectedMedias(): void {
        this.medias = [];
        this.globalFilter = "";
        this.filters.tags = [];
        this.filters.type = [];
    }

    manageFilter(event: any, selected: boolean = true, filter: string = "tags"): void {
        const objectProperty = event.hasOwnProperty("id");

        const found = this.filters[filter].find((t) => (objectProperty ? event.id === t.id : event === t));

        if (selected) {
            if (!found) {
                this.filters[filter].push(event);
                this.getMedias(this.currentDatatableFilters);
            }
        } else {
            if (found) {
                const index = this.filters[filter].findIndex((f) => (objectProperty ? event.id === f.id : event === f));
                this.filters[filter].splice(index, 1);
                this.getMedias(this.currentDatatableFilters);
            }
        }
    }

    getCustomFilters(): any {
        let customFilters = { ...this.filters };

        Object.keys(customFilters).forEach((cf) => {
            customFilters[cf] = this.filters[cf].map((f) => f.id);
        });

        return customFilters;
    }

    changeHideExpireMedia(event) {
        this.state = event;
        this.getMedias(this.currentDatatableFilters);
    }
}
