<div class="card mb-2 p-0">
    <div class="card-header text-center d-flex">
        <ng-container *ngIf="!updateName">
            <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ fieldset.name }} 
                <span *ngIf="fieldset.sourceId && fieldset.sourceName" class="font-italic">({{ "pim.fieldsets.duplicateFrom" | translate }} : 
                    <a [routerLink]="['/pim', 'fieldset', 'update', fieldset.sourceId]" target="_blank">{{ fieldset.sourceName }}</a>)
                </span>
            </h4>
            <fa-icon container="body" [ngbTooltip]="'pim.actions.edit'| translate" (click)="updateName = true" class="text-warning font-lg pointer" [icon]="faEdit"></fa-icon>
        </ng-container>
        <ng-container *ngIf="updateName">
            <input class="form-control form-control-sm col-9 col-md-4 mx-auto text-center font-weight-bolder" type="text" [(ngModel)]="fieldset.name">
            <fa-icon *ngIf="fieldset.name !== nameAtTheOpening" (click)="saveName()" class="text-primary font-lg pointer" [icon]="faSave"></fa-icon>
            <fa-icon (click)="updateName = false" class="text-danger font-lg pointer ml-2" [icon]="faClose"></fa-icon>
        </ng-container>
    </div>
    <div class="card-body p-0">
        <form [formGroup]="formArray">
            <div class="table-responsive">
                <table class="table table-striped mb-0">
                    <thead>
                        <tr>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.fieldsets.fieldName" | translate }} <i *ngIf="fieldset.sourceId && fieldset.fields.length" role="button" (click)="showInputReferentName = !showInputReferentName" class="pi pi-info-circle" pTooltip="{{ 'pim.fieldsets.showReferences' | translate }}"></i></th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.fieldsets.protocol" | translate }} <i *ngIf="fieldset.sourceId && fieldset.fields.length" role="button" (click)="showInputReferentProtocol = !showInputReferentProtocol" class="pi pi-info-circle" pTooltip="{{ 'pim.fieldsets.showReferences' | translate }}"></i></th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.fieldsets.fieldType" | translate }}</th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.elementTypes.fieldSource" | translate }}</th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.fieldsets.fieldListMultiple" | translate }}</th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "pim.elementTypes.fieldMandatory" | translate }}</th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "indicator.name" | translate }}</th>
                            <th style="border: 0;" class="text-center" scope="col">{{ "table.actions" | translate }}</th>
                        </tr>
                    </thead>
                    <tbody [appSortable]="sortableJSOptions">
                        <tr *ngFor="let control of formArray.controls; let i = index">
                            <td style="min-width: 10rem;">
                                <input class="form-control" [ngClass]="control.controls.name.valid ? 'is-valid' : 'is-invalid'" type="text" [formControl]="control.controls.name">
                                <small *ngIf="showInputReferentName" id="inputName-{{i}}" class="form-text text-muted">{{ getInputReferent(control.controls.id.value, 'name') }}</small>
                                <div *ngIf="control.controls.name.invalid && !control.controls.name.pristine" class="invalid-feedback">
                                    <span *ngIf="control.controls.name.hasError('required')">{{ "pim.fieldsets.error.required" | translate }}</span>
                                    <!-- @TODO: See why never called : -->
                                    <span *ngIf="control.controls.name.hasError('uniqueValue')">{{ "pim.fieldsets.error.unique" | translate }}</span>
                                </div>
                            </td>
                            <td style="min-width: 10rem;">
                                <input class="form-control" [ngClass]="control.controls.protocol.valid ? 'is-valid' : 'is-invalid'" type="text" [formControl]="control.controls.protocol">
                                <small *ngIf="showInputReferentProtocol" id="inputProtocol-{{i}}" class="form-text text-muted">{{ getInputReferent(control.controls.id.value, 'protocol') }}</small>
                                <div *ngIf="control.controls.protocol.invalid && !control.controls.protocol.pristine" class="invalid-feedback">
                                    <span *ngIf="control.controls.protocol.hasError('uniqueValue')">{{ "pim.fieldsets.error.unique" | translate }}</span>
                                </div>
                            </td>
                            <td style="min-width: 10rem;">
                                <select class="form-control" [ngClass]="control.controls.fieldType.valid ? 'is-valid' : 'is-invalid'" [formControl]="control.controls.fieldType">
                                    <option *ngFor="let fieldType of fieldTypes | filterType:control.controls.fieldType.value:(control.controls.id.value !== -1)" [value]="fieldType">{{ "fieldType." + fieldType | translate}}</option>
                                </select>
                                <div *ngIf="control.controls.fieldType.invalid && !control.controls.fieldType.pristine" class="invalid-feedback">
                                    <span *ngIf="control.controls.fieldType.hasError('required')">{{ "pim.fieldsets.error.required" | translate }}</span>
                                </div>
                            </td>
                            <td style="min-width: 10rem;" *ngIf="control.value.fieldType !== 'list' && control.value.fieldType !== 'table' && control.value.fieldType !== 'fieldset' && control.value.fieldType !== 'productLink'">
                                <input class="form-control" type="text" disabled value="{{ 'pim.fieldsets.noList' | translate }}">
                            </td>
                            <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'table'" class="tableOverlayPanelContainer">
                                <div class="row ml-1">
                                    <button class="btn btn-primary btn-block text-left col-10" data-toggle="modal" data-target="#tableModal" (click)="op.hide(); openConfigureTableModal(formArray.controls[i], i)">{{ 'general.table.configure' | translate }}</button>
                                    <i class="pi pi-eye col-2" type="button" (click)="openConfigureTableModal(formArray.controls[i], i); op.toggle($event)"></i>
                                    <ng-template #overlayPanelDiv></ng-template>
                                    <p-overlayPanel class="my-overlayPanel tableOverlayPanel" #op [appendTo]="overlayPanelDiv" [showCloseIcon]="true">
                                        <app-form-table [id]="'overlayPanelTable-' + i" [cells]="currentTable" [overlayPanelMode]="true"></app-form-table>
                                      </p-overlayPanel>
                                    <div *ngIf="formArray.controls[i].value" class="modal fade" id="tableModal" tabindex="-1" role="dialog" aria-labelledby="tableModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="tableModalLabel">{{ title }}</h5>

                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>

                                                <div class="modal-body">
                                                    <app-form-table [id]="formArray.controls[i].value.id" [cells]="currentTable" (cellsEmitter)="setCells($event)"></app-form-table>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'general.close' | translate }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'list'">
                                <select class="form-control" [ngClass]="(control.controls.list.valid || control.controls.list.disabled) ? 'is-valid' : 'is-invalid'" [formControl]="control.controls.list">
                                    <option *ngFor="let list of lists" [value]="list.id">{{ list.name }}</option>
                                </select>
                                <div *ngIf="control.controls.list.invalid && !control.controls.list.pristine && !control.controls.list.disabled" class="invalid-feedback">
                                    <span *ngIf="control.controls.list.hasError('required')">{{ "pim.fieldsets.error.required" | translate }}</span>
                                </div>
                            </td>
                            <td class="text-center align-middle" *ngIf="control.value.fieldType === 'list' || control.value.fieldType === 'media'">
                                <label class="switch switch-success mb-0">
                                    <input type="checkbox" class="switch-input" [formControl]="control.controls.multiple">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                            <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'fieldset'">
                                <select class="form-control"
                                    [ngClass]="(control.controls.defaultValue.valid || control.controls.defaultValue.disabled) ? 'is-valid' : 'is-invalid'"
                                    [formControl]="control.controls.defaultValue">
                                    <option *ngFor="let fieldset of fieldsets" [value]="fieldset.id">{{
                                        fieldset.name }}</option>
                                </select>
                                <div *ngIf="control.controls.defaultValue.invalid && !control.controls.defaultValue.pristine && !control.controls.defaultValue.disabled"
                                    class="invalid-feedback">
                                    <span *ngIf="control.controls.defaultValue.hasError('required')">{{
                                        "pim.elementTypes.error.required" | translate }}</span>
                                </div>
                                <figure class="w-100 text mt-1" *ngIf="control.controls.defaultValue && control.controls.defaultValue.value && control.value.collectionReferent && inputs">
                                    <div class="text-xs font-bold">
                                        {{ "pim.fieldsets.fieldReference"  | translate }} :
                                    </div>
                                    <figcaption class="font-italic text-xs">
                                        {{ getInputRefName(control.controls.defaultValue.value, control.value.collectionReferent) }}
                                    </figcaption>
                                </figure>    
                            </td>
                            <td style="min-width: 10rem;" *ngIf="control.value.fieldType === 'productLink'">
                                <select class="form-control"
                                    [ngClass]="(control.controls.defaultValue.valid || control.controls.defaultValue.disabled) ? 'is-valid' : 'is-invalid'"
                                    [formControl]="control.controls.defaultValue">
                                    <option *ngFor="let elementType of elementTypes" [value]="elementType.id">{{
                                        elementType.name }}</option>
                                </select>
                                <div *ngIf="control.controls.defaultValue.invalid && !control.controls.defaultValue.pristine && !control.controls.defaultValue.disabled"
                                    class="invalid-feedback">
                                    <span *ngIf="control.controls.defaultValue.hasError('required')">{{
                                        "pim.elementTypes.error.required" | translate }}</span>
                                </div>
                            </td>
                            <td *ngIf="control.value.fieldType !== 'list' && control.value.fieldType !== 'media'"></td>
                            <td class="text-center align-middle">
                                <label class="switch switch-success mb-0">
                                    <input type="checkbox" class="switch-input" [formControl]="control.controls.mandatory">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                            <td class="text-center align-middle">
                                <label class="switch switch-success mb-0">
                                    <input type="checkbox" class="switch-input" [formControl]="control.controls.salabilityIndicator">
                                    <span class="switch-slider"></span>
                                </label>
                            </td>
                            <td class="d-flex justify-content-center">
                                <button [ngbTooltip]="'tooltip.delete'| translate" class="btn btn-danger" (click)="deleteField(i)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                                <button [ngbTooltip]="'tooltip.move'| translate" class="btn btn-primary ml-1 sortable">
                                    <fa-icon [icon]="faSort"></fa-icon>
                                </button>
                                <button (click)="openLinkInputRef(formArray, i)"
                                    [ngbTooltip]="'pim.fieldsets.inputReference'| translate"
                                    class="ml-1 btn btn-primary" *ngIf="control.value.fieldType === 'fieldset'">
                                    <fa-icon [icon]="faLink"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="formArray.controls.length === 0">
                            <td colspan="9" class="text-center font-weight-bold">{{ "general.no.data" | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="btn-group ml-auto">
                <button class="btn btn-primary" (click)="addField()">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.field.add' | translate }}</span>
                </button>
                <button [disabled]="formArray.invalid || formArray.controls.length === 0 || formArray.pristine || isnotValid" class="btn btn-primary" (click)="saveFields()">
                    <fa-icon [icon]="faSave"></fa-icon>
                    <span class="d-none d-md-inline-block ml-1">{{ 'general.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<swal #linkInputRef confirmButtonText="{{ 'general.save' | translate }}" [showCloseButton]="true">
    <div *swalPortal>
        <form [formGroup]="formLinkInputRef" class="mt-2">
            <div class="row input-group mt-3">
                <label class="mb-3 text alert alert-info">
                    <i class="pi pi-info-circle"></i>
                    {{ "pim.fieldsets.labelReference" | translate }}
                </label>
                <select class="custom-select" formControlName="input" (change)="onSelectChange($event)">
                    <option [value]=null></option>

                    <option *ngFor="let input of inputs[actualSection.controls[actualField].get('defaultValue').value]" [value]="input.id">
                        {{ input.name }}
                    </option>
                </select>
            </div>
            <span *ngIf="isnotValid|| isNotValidTooltip()" class="text-xs text-red mt-2">{{ "pim.fieldsets.mandatorySelectReference" | translate }}</span>
        </form>
    </div>
</swal>
