<div class="px-2" style="max-height: 84vh; overflow-y: auto; overflow-x: hidden;" id="thumbs-container">
    <div class="card">
        <div class="card-body row">
            <div class="col-sm-12">
                <h3 class="text-center text-primary">
                    <span>{{ 'general.listOf' | translate }} {{ "dam.mediaNumberTotal" | translate }}</span>
                </h3>
                <div class="col text-right">
                    <button (click)="openCart = !openCart" id="cart" class="btn btn-maestro-yellow mt-1"
                        data-toggle="modal" data-target="#cartModal">
                        {{ "dam.cartButton" | translate }}
                        <span *ngIf="cart.length > 0"
                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                            cart.length }}</span>
                    </button>
                </div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="filterOptions.length > 0">
                <hr />
                <div class=" row">
                    <div class="col-8">
                        <div class="d-flex">
                            <div class="btn-group mr-1" role="group" *ngFor="let filterOption of filterOptions">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="removeFilterOption(filterOption.name)">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </button>
                                <button class="btn btn-primary btn-sm mr-1" style="cursor: default;" type="button"
                                    disabled>
                                    <span class="d-none d-md-inline-block">{{ 'filter.' + filterOption.type | translate
                                        }} :&nbsp;</span>
                                    <span>{{ filterOption.name }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right">
                        <div class="btn-group" role="group" (click)="removeAllFilterOptions()">
                            <button class="btn btn-danger btn-sm" type="button">
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                            <button class="d-none d-md-block btn btn-primary btn-sm" type="button">
                                {{ 'general.clear' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-dataview [mediaParam]="mediaParam" [dataviewContent]="medias" (lazyEvent)="loadList($event)" [totalRecords]="recordsFiltered"
        [sortOptions]="sortOptions" (searchEvent)="loadListWithSearch($event)"
        (tagRemoved)="loadList(currentDatatableFilters)" (tagFiltered)="addFilterTagFromThumb($event)"
        (addCart)="addToCart($event)" (thumbUpdateEvent)="removeAndReload($event)" (deleted)="onDeleteMedia($event)"
        [buttons]="buttons" type="dam" [cartLength]="cart.length" [filters]="allFilters"
        (filterExpireMedia)="changeHideExpireMedia($event)"
        (massTagStart)="massTagSelection($event)" (massTagEnd)="loadList(currentDatatableFilters)" [first]="currentDatatableFilters.first ? currentDatatableFilters.first : 0">
    </app-dataview>
</div>
<!-- Modal -->
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ "dam.cartButton" | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled" style="max-height: 70vh;overflow-x: hidden!important;">
                    <li *ngFor="let media of cart" class="pb-2 pt-2">
                        <div class="row">
                            <img class="col-4" [src]="media.thumbPath ? damUrl+media.thumbPath : getMediaThumb(media.extension, media.thumb)"
                                style="max-height: 5rem; object-fit: contain;" alt="cartImage">
                            <span [ngbTooltip]="media.name" class="col-6 text-truncate mt-3">{{ media.name }}</span>
                            <div class="col-2 mt-2">
                                <button (click)="removeFromCart(media.id)" class="ml-auto btn btn-sm-danger">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "general.close" | translate
                    }}</button>
                <button (click)="emptyCart()" class="btn btn-danger" data-dismiss="modal">{{ "dam.emptyCart" | translate
                    }}</button>
                <button (click)="downloadCart()" [disabled]="cart.length === 0" class="btn btn-primary">{{
                    "dam.download" | translate }}</button>
            </div>
        </div>
    </div>
</div>
