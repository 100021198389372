const url = "adagio.maestro-solution.fr";
const protocol = "https://";

export const environment = {
    production: false,
    adminApiUrl: protocol + "admin-api." + url,
    adminAuthUrl: protocol + "admin-auth." + url,
    pimUrl: protocol + "pim." + url,
    damUrl: protocol + "dam." + url,
    projectsUrl: protocol + "projects." + url,
    cdfUrl: protocol + "cdf." + url,
    translationUrl: protocol + "translation." + url,
    historisationUrl: protocol + "historisation." + url,
    templateUrl: protocol + "templates." + url,
    dashboardUrl: protocol + "dashboard." + url + "/graphql",
    mercureHubUrl: protocol + "mercure." + url + "/.well-known/mercure",
    showVersion: true,
    showCompleteSalabilityIndicatorsInterface: false,
    showMinifiedSalabilityIndicatorsInterface: true,
    tagAsSalabilityIndicator: false,
    displayMandatoryIndicator: true,
    displaySalabilityIndicator: true,
};
